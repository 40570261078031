import { Box, Typography } from "@mui/material";
import { TablePlaceHolder } from "components/PlaceHolder";
import { BaseDialog } from "components/BaseDialog";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { CarButton } from "components/Buttons";
import {
  FieldTypeEnum,
  useSimulationQaReportSimPathCalcualtionDataCreateQuery,
} from "api/carApi.generated";
import { useSimulationDebugFieldList } from "./useSimulationDebugFieldList";
import { useMemo, useState } from "react";
import { SimulationAnalyzePathTable } from "./SimulationAnalyzePathTable";
import {
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { DecimalOptions, isDefined } from "utils";
import { CarSelectOptionField } from "components/Inputs";
import { useTimeHorizonSelector } from "./useTimeHorizonSelector";
import { CarAssetClassSelector } from "components/AssetClassSelector";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { CarNumberField } from "components/NumberField";

export interface CarSimAnalyzePathColumn {
  year: number;
}
export interface CarSimAnalyzePathRow {
  label: string;
  code: string;
  fieldType: FieldTypeEnum;
  values: (number | null)[];
}

export interface CarSimAnalyzePathTable {
  columns: CarSimAnalyzePathColumn[];
  rows: CarSimAnalyzePathRow[];
}

interface SimulationAnalyzePathsDialogProps {
  simulationJobId: string;
  assetClassCode: string;
  pathNumber: number;
  fileSuffix: string;
  onApply: () => void;
  onCancel: () => void;
}

const decimalOptions: DecimalOptions = {
  decimalPlaces: 2,
  forceShowDecimals: true,
};

export const SimulationAnalyzePathsDialog = (
  props: SimulationAnalyzePathsDialogProps,
) => {
  const assetClasses = useAssetClasses({
    type: AssetClassLoadType.bySimulationJobId,
    simulationJobId: props.simulationJobId,
  });
  const excelExport = useExcelExport();

  const fields = useSimulationDebugFieldList();

  const timeHorizonSelector = useTimeHorizonSelector();

  const [acCode, setAcCode] = useState(props.assetClassCode);
  const [currentPathNumber, setCurrentPathNumber] = useState<number>(
    props.pathNumber,
  );
  const [pathNumber, setPathNumber] = useState<number | undefined>(
    props.pathNumber,
  );

  const pageContent = usePageContent();

  const acName = assetClasses.items.find((i) => i.code === acCode)?.name ?? "";

  const { data, isLoading, isFetching } =
    useSimulationQaReportSimPathCalcualtionDataCreateQuery({
      simulationAnalyzePathCalcFieldsInput: {
        simulation_job_id: props.simulationJobId,
        ac_code: acCode,
        path: currentPathNumber,
      },
    });

  const table = useMemo(() => {
    if (!data) {
      return undefined;
    }

    const emptyRowTemplate =
      data.fields
        .find((i) => (i.data.at(0)?.length ?? 0) > 0)
        ?.data.at(0)
        ?.map((i) => null) ?? [];

    const fullTable: CarSimAnalyzePathTable = {
      columns: emptyRowTemplate.map((i, idx) => ({ year: idx })) ?? [],
      rows: data.fields.map((i) => {
        const field = fields.items.find((fl) => fl.id === i.field.id);
        let values: (number | null)[] = i.data.at(0) ?? [];
        if (values.length === 0) {
          values = Array.from(emptyRowTemplate);
        }
        return {
          label: field?.label ?? "",
          code: field?.filterCode ?? "",
          fieldType: field?.fieldType ?? "number",
          values,
        };
      }),
    };

    const slicedTable: CarSimAnalyzePathTable = {
      columns: timeHorizonSelector.sliceTimeHorizon(fullTable.columns),
      rows: fullTable.rows.map((i) => ({
        ...i,
        values: timeHorizonSelector.sliceTimeHorizon(i.values),
      })),
    };

    return { full: fullTable, sliced: slicedTable };
  }, [data, fields.items, timeHorizonSelector]);

  const handleJump = () => {
    if (isDefined(pathNumber)) {
      setCurrentPathNumber(pathNumber);
    }
  };

  const handleExport = () => {
    const excelTable: ExcelTable = {
      name: `Path ${pathNumber}`,
      columns: [
        {
          label: "Paths",
          format: ExcelDataFormat.general,
          charWidth: 40,
        },
        ...(table?.full.columns.map((i) => ({
          label: i.year.toString(),
          format: ExcelDataFormat.float,
          decimalOptions,
        })) ?? []),
      ],
      rows:
        table?.full.rows.map((i) => [
          i.label,
          ...i.values.map((v) => (isDefined(v) ? v : "")),
        ]) ?? [],
      onGetCellFormat: ({ colIdx, rowIdx, format }) =>
        colIdx === 0
          ? format
          : table?.full.rows.at(rowIdx)?.fieldType === "percentage"
          ? ExcelDataFormat.percent
          : ExcelDataFormat.float,
    };

    excelExport.exportAllExcel({
      fileName: `Path Analysis - ${props.fileSuffix} - ${acName} - Path ${currentPathNumber}`,
      tables: [excelTable],
    });
  };

  return (
    <BaseDialog
      sx={{ width: "100vw", maxWidth: "100vw", height: "100vh" }}
      onClose={props.onCancel}
      actions={null}
    >
      <TablePlaceHolder
        isLoading={pageContent.isLoading || fields.isLoading || isLoading}
        rows={8}
      >
        <CarPageContent
          content={pageContent.getContent(
            CarPageContentCode.SIMULATION_ANALYSIS_X_RAY,
          )}
        />
        <Box sx={{ mt: 3, display: "flex", gap: 5 }}>
          <CarAssetClassSelector
            sx={{
              width: 250,
            }}
            label="Asset Class"
            value={acCode}
            onChange={setAcCode}
            items={assetClasses.items}
            valueField="code"
          />
          <CarSelectOptionField
            sx={{
              width: 140,
            }}
            label="Time Horizon"
            value={timeHorizonSelector.value}
            onChange={timeHorizonSelector.onChange}
            options={timeHorizonSelector.options}
          />
          <CarNumberField
            sx={{
              width: 140,
            }}
            label="Jump to Path"
            value={pathNumber}
            onChange={setPathNumber}
          />
          <CarButton sx={{ alignSelf: "flex-end" }} onClick={handleJump}>
            Jump
          </CarButton>
        </Box>
        <Box sx={{ mt: 3, display: "flex", alignItems: "flex-end" }}>
          <Typography variant="subhSemiBold">{`${acName} - Path ${currentPathNumber}`}</Typography>
          <CarButton sx={{ ml: "auto" }} onClick={handleExport}>
            Export
          </CarButton>
        </Box>
        {table ? (
          <Box
            sx={{
              mt: 1.5,
              backgroundColor: "gray1",
              border: "1px solid",
              borderColor: "gray3",
              display: "flex",
              flexDirection: "column",
              p: 5,
              pt: 2.5,
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="par02SemiBold" sx={{ alignSelf: "center" }}>
                Years
              </Typography>
            </Box>
            <TablePlaceHolder rows={8} isLoading={isFetching}>
              <SimulationAnalyzePathTable
                table={table?.sliced}
                sx={{
                  mt: 2.5,
                }}
              />
            </TablePlaceHolder>
          </Box>
        ) : (
          <Box
            sx={{
              mt: 5,
              flex: "auto",
              backgroundColor: "white",
              border: "1px solid",
              borderColor: "gray3",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: 448,
            }}
          >
            <Typography variant="par02Regular">No data loaded yet.</Typography>
          </Box>
        )}
      </TablePlaceHolder>
    </BaseDialog>
  );
};
