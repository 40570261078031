import { ComponentType, useMemo } from "react";
import { useLogout } from "./useLogout";
import { useUserAccess } from "./useUserAccess";
import {
  HomeIcon,
  SimulationIcon,
  AssetAllocationIcon,
  AssetClassAnalysisIcon,
  FinancialPlanningIcon,
  PortfoliosIcon,
  HelpIcon,
} from "./sideBarIcons";
import { rootPages } from "route.const";
import { openInNewTab } from "utils";

interface SideBarItem {
  icon: ComponentType;
  url: string;
  label: string;
  end?: boolean;
  visible: boolean;
  onClick?: () => void;
}

export const useSideBar = () => {
  const helpUrl = "https://docs.caravelconcepts.com/en/";

  const userAccess = useUserAccess();

  const { logout } = useLogout();
  return useMemo(() => {
    const items: SideBarItem[] = [
      {
        icon: HomeIcon,
        label: "Home",
        url: rootPages.home,
        end: true,
        visible: true,
      },
      {
        icon: SimulationIcon,
        label: "Simulation",
        url: rootPages.simulationUrl,
        visible: userAccess.canAccessSimulation,
      },
      {
        icon: AssetClassAnalysisIcon,
        label: "AC Analysis",
        url: rootPages.assetClassAnalysisUrl,
        visible: userAccess.canAccessAcAnalysis,
      },
      {
        icon: PortfoliosIcon,
        label: "Portfolios",
        url: rootPages.portfolioAnalysisUrl,
        visible: userAccess.canAccessPortfolioAnalysis,
      },
      {
        icon: AssetAllocationIcon,
        label: "Allocation",
        url: rootPages.assetAllocationUrl,
        visible: userAccess.canAccessAssetAllocation,
      },
      {
        icon: FinancialPlanningIcon,
        label: "Planning",
        url: rootPages.financialPlanningUrl,
        visible: userAccess.canAccessPlanning,
      },
      {
        icon: HelpIcon,
        label: "Help",
        url: helpUrl,
        onClick: () => openInNewTab(helpUrl),
        visible: true,
      },
    ].filter((i) => i.visible);

    return {
      isLoading: userAccess.isLoading,
      items,
      logout,
      helpUrl,
    };
  }, [logout, userAccess]);
};
