import { Box, Typography } from "@mui/material";
import { FullScreenBackground } from "components/FullScreenBackground";
import { CarFooter } from "components/Footer";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSessionStorage } from "features/general/useLocalStorage";
import { rootPages } from "route.const";
import { useFirm } from "pages/settings/useFirm";
import { LoginTransparentBox } from "pages/login/LoginComponents";
import { useLogout } from "app/useLogout";
import { PurchaseSeatsForm } from "pages/settings/PurchaseSeatsForm";

const isPaymentSkippedKey = "isPaymentSkipped";

export const PaymentChecker = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    firm: { is_paid },
    isTrialExpired,
  } = useFirm();
  const [isPaymentSkipped] = useSessionStorage(isPaymentSkippedKey, false);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if ((!is_paid && !isPaymentSkipped) || isTrialExpired) {
      navigate(rootPages.payment);
    }
  }, [isLoading, is_paid, isPaymentSkipped, isTrialExpired, navigate]);

  return <></>;
};

export const PaymentPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setPaymentSkipped] = useSessionStorage(isPaymentSkippedKey, false);
  const navigate = useNavigate();

  const firm = useFirm();
  const { logout } = useLogout();

  useEffect(() => {
    if (firm.isPaid) {
      navigate(rootPages.assetAllocationUrl);
    }
  }, [firm.isPaid, navigate]);

  const handleSkipPayment = () => {
    setPaymentSkipped(true);
    navigate(rootPages.assetAllocationUrl);
  };

  const handleBackToLogin = () => {
    logout();
  };

  return (
    <FullScreenBackground
      sx={{
        alignItems: "center",
        justifyContent: "center",
        overflow: "auto",
        display: "flex",
      }}
    >
      <Box
        sx={{
          m: "auto",
          py: 2,
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          minHeight: 0,
        }}
      >
        <LoginTransparentBox sx={{ px: "66px" }}>
          <PurchaseSeatsForm
            onSuccess={() => {}}
            backButtonLabel="Skip"
            onBack={firm.isTrialExpired ? undefined : handleSkipPayment}
          />
          <Typography
            variant="par02Regular"
            sx={{
              alignSelf: "center",
              mt: 3,
              mb: -4,
              span: {
                color: "primary.main",
                textDecoration: "underline",
                cursor: "pointer",
              },
            }}
          >
            Back to <span onClick={handleBackToLogin}>Login</span>
          </Typography>
        </LoginTransparentBox>
        <CarFooter
          sx={{ py: 1.5, alignSelf: "center" }}
          isWhite
          isCopyrightOnly
          hasPrivacyPolicy
        />
      </Box>
    </FullScreenBackground>
  );
};
