import { useRoles } from "./useRoles";
import {
  useCommonAuthSelfRetrieveQuery,
  useCommonCmpasModuleListQuery,
  useCommonCmpasModuleSectionListQuery,
} from "api/carApi.generated";
import { CARAVEL_FIRM_ID } from "pages/settings/useUser";
import { useMemo } from "react";
import { debugLog, debugTable, isDefined } from "utils";

export enum CarModuleSectionCode {
  "ADD_PORTFOLIO_GROUP_ADD_MODULES" = "ADD_PORTFOLIO_GROUP_ADD_MODULES",
  "ADD_PORTFOLIO_GROUP_SELECT_ASSET_CLASS" = "ADD_PORTFOLIO_GROUP_SELECT_ASSET_CLASS",
  "ALL_SIMULATED_FORWARD_PROBABILITY_PRINTABLE_PDF" = "ALL_SIMULATED_FORWARD_PROBABILITY_PRINTABLE_PDF",

  "ASSET_CLASS_DASHBOARD" = "ASSET_CLASS_DASHBOARD",
  "ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND" = "ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND", // rename to ASSET_CLASS_MONTHLY_REPORT_DISTANCE_FROM_TREND
  "ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN" = "ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN",
  "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK" = "ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK",
  "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS" = "ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS",
  "ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY" = "ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY",
  "ASSET_CLASS_MONTHLY_REPORT_SUMMARY" = "ASSET_CLASS_MONTHLY_REPORT_SUMMARY",

  "PORTFOLIO_ANALYSIS_DASHBOARD" = "PORTFOLIO_ANALYSIS_DASHBOARD",
  "PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION" = "PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION",
  "PORTFOLIO_ANALYSIS_CREATE_MODEL" = "PORTFOLIO_ANALYSIS_CREATE_MODEL",
  "PORTFOLIO_ANALYSIS_OPTIMIZATION" = "PORTFOLIO_ANALYSIS_OPTIMIZATION",
  "PORTFOLIO_ANALYSIS_EDIT_COMPARE" = "PORTFOLIO_ANALYSIS_EDIT_COMPARE",
  "PORTFOLIO_ANALYSIS_ANALYZE" = "PORTFOLIO_ANALYSIS_ANALYZE",

  "ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS" = "ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS", // rename to ANALYZE_PORTFOLIO_GROUP_ASSET_CLASS_SELECTION
  "ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION" = "ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION", // rename to ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATIONS
  "ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE" = "ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE",
  "ANALYZE_PORTFOLIO_GROUP_OPTIMIZER" = "ANALYZE_PORTFOLIO_GROUP_OPTIMIZER", // rename to ANALYZE_PORTFOLIO_GROUP_OPTIMIZATION

  // non existing pages
  "ANALYZE_PORTFOLIO_GROUP_ANALYZE" = "ANALYZE_PORTFOLIO_GROUP_ANALYZE",

  "ANALYZE_PORTFOLIO_GROUP_REPORTS_ASSET_CLASS_ANALYSIS" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_ASSET_CLASS_ANALYSIS",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_MODULE",
  "ANALYZE_PORTFOLIO_GROUP_REPORTS_SUMMARY" = "ANALYZE_PORTFOLIO_GROUP_REPORTS_SUMMARY",
  "PORTFOLIO_GROUP_PUBLISH_SELECTION" = "PORTFOLIO_GROUP_PUBLISH_SELECTION", // rename to ANALYZE_PORTFOLIO_GROUP_SELECTION
  "ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS" = "ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS", // rename to ANALYZE_PORTFOLIO_GROUP_PUBLISH

  "CMPAS_ACCEPT_INVITATION" = "CMPAS_ACCEPT_INVITATION",
  "CMPAS_ASSET_ALLOCATION_DASHBOARD" = "CMPAS_ASSET_ALLOCATION_DASHBOARD",
  "CMPAS_HOME" = "CMPAS_HOME",
  "CMPAS_LOGIN" = "CMPAS_LOGIN",
  "CMPAS_PAYMENT_COMPLETE" = "CMPAS_PAYMENT_COMPLETE",
  "CMPAS_PAYMENT" = "CMPAS_PAYMENT",
  "CMPAS_RESET_PASSWORD" = "CMPAS_RESET_PASSWORD",
  "DFT_PRINTABLE_REPORT" = "DFT_PRINTABLE_REPORT",
  "PERFORMANCE _RISK_PRINTABLE_PDF" = "PERFORMANCE _RISK_PRINTABLE_PDF",
  "PLANNING_ACTION_PRINTABLE_REPORT" = "PLANNING_ACTION_PRINTABLE_REPORT",
  "PLANNING_ADD_CLIENT" = "PLANNING_ADD_CLIENT",
  "PLANNING_CLIENT_LIST" = "PLANNING_CLIENT_LIST",
  "PLANNING_CLIENT_SUMMARY" = "PLANNING_CLIENT_SUMMARY",
  "PLANNING_PLAN_INPUT" = "PLANNING_PLAN_INPUT",
  "PLANNING_PRINTABLE_REPORT" = "PLANNING_PRINTABLE_REPORT",
  "PLANNING_PROGRESS_MONITORING" = "PLANNING_PROGRESS_MONITORING",
  "PLANNING_WORKBENCH_ALLOCATION_BY_ACCOUNT" = "PLANNING_WORKBENCH_ALLOCATION_BY_ACCOUNT",
  "PLANNING_WORKBENCH_CAP_GAINS" = "PLANNING_WORKBENCH_CAP_GAINS",
  "PLANNING_WORKBENCH_HOUSEHOLD_ALLOCATION" = "PLANNING_WORKBENCH_HOUSEHOLD_ALLOCATION",
  "PLANNING_WORKBENCH_PLAN_RESULTS" = "PLANNING_WORKBENCH_PLAN_RESULTS",
  "PLANNING_WORKBENCH_PUBLISH_PLAN" = "PLANNING_WORKBENCH_PUBLISH_PLAN",
  "PLANNING_WORKBENCH_STRESS_TEST" = "PLANNING_WORKBENCH_STRESS_TEST",

  "PUBLISH_PRINTABLE_PDF" = "PUBLISH_PRINTABLE_PDF",
  "REPORT_ASSET_CLASS_ANALYSIS_PRINTABLE_PDF" = "REPORT_ASSET_CLASS_ANALYSIS_PRINTABLE_PDF",
  "REPORT_MODULE_PRINTABLE_PDF" = "REPORT_MODULE_PRINTABLE_PDF",
  "REPORT_SUMMARY_PRINTABLE_PDF" = "REPORT_SUMMARY_PRINTABLE_PDF",
  "SETTINGS_ASSET_CLASS_IMPLEMENTATION" = "SETTINGS_ASSET_CLASS_IMPLEMENTATION",
  "SETTINGS_BILLING" = "SETTINGS_BILLING",
  "SETTINGS_DISCLOSURES" = "SETTINGS_DISCLOSURES",
  "SETTINGS_MANAGE_FIRM" = "SETTINGS_MANAGE_FIRM",
  "SETTINGS_NOTIFICATIONS" = "SETTINGS_NOTIFICATIONS",
  "SETTINGS_USER_PROFILE" = "SETTINGS_USER_PROFILE",
  "SIMULATION_ANALYSIS_AB_TEST_RETURNS" = "SIMULATION_ANALYSIS_AB_TEST_RETURNS",
  "SIMULATION_ANALYSIS_AB_TEST_SIMULATED_FORWARD_PROBABILITIES" = "SIMULATION_ANALYSIS_AB_TEST_SIMULATED_FORWARD_PROBABILITIES",
  "SIMULATION_ANALYSIS_AB_TEST_SUMMARY" = "SIMULATION_ANALYSIS_AB_TEST_SUMMARY",
  "SIMULATION_ANALYSIS_AB_TEST_YIELDS" = "SIMULATION_ANALYSIS_AB_TEST_YIELDS",
  "SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB" = "SIMULATION_ANALYSIS_DATA_EXPLORER_FILTER_LB",
  "SIMULATION_ANALYSIS_MODELS_BEHAVIOR" = "SIMULATION_ANALYSIS_MODELS_BEHAVIOR",
  "SIMULATION_ANALYSIS_MODELS_MODELS" = "SIMULATION_ANALYSIS_MODELS_MODELS",
  "SIMULATION_ANALYSIS_MODELS_RUN" = "SIMULATION_ANALYSIS_MODELS_RUN",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_CORRELATIONS" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_CORRELATIONS",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_DATA_EXPLORER" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_DATA_EXPLORER",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_DISTANCE_FROM_TREND" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_DISTANCE_FROM_TREND",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_RETURNS" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_RETURNS",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_SIMULATED_FORWARD_PROBABILITIES" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_SIMULATED_FORWARD_PROBABILITIES",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_SUMMARY" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_SUMMARY",
  "SIMULATION_ANALYSIS_PRICE_DRIVEN_YIELDS" = "SIMULATION_ANALYSIS_PRICE_DRIVEN_YIELDS",
  "SIMULATION_ANALYSIS_RUN" = "SIMULATION_ANALYSIS_RUN",
  "SIMULATION_ANALYSIS_SIMULATION_RUN_SETTINGS" = "SIMULATION_ANALYSIS_SIMULATION_RUN_SETTINGS",
  "SIMULATION_ANALYSIS_SIMULATION_YIELD_CURVES" = "SIMULATION_ANALYSIS_SIMULATION_YIELD_CURVES",
  "SIMULATION_ANALYSIS_STATIC_CORRELATIONS" = "SIMULATION_ANALYSIS_STATIC_CORRELATIONS",
  "SIMULATION_ANALYSIS_STATIC_RETURNS" = "SIMULATION_ANALYSIS_STATIC_RETURNS",
  "SIMULATION_ANALYSIS_STATIC_SUMMARY" = "SIMULATION_ANALYSIS_STATIC_SUMMARY",
  "SIMULATION_ANALYSIS_X_RAY" = "SIMULATION_ANALYSIS_X_RAY",
  "SINGLE_SIMULATED_FORWARD_PROBABILITY_PRINTABLE_PDF" = "SINGLE_SIMULATED_FORWARD_PROBABILITY_PRINTABLE_PDF",
  "VIEW_PORTFOLIO_GROUP" = "VIEW_PORTFOLIO_GROUP",

  // non existing pages
  "PLANNING_WORKBENCH" = "PLANNING_WORKBENCH",

  "SIMULATION_ANALYSIS_PRICE_DRIVEN" = "SIMULATION_ANALYSIS_PRICE_DRIVEN",
  "SIMULATION_ANALYSIS_STATIC" = "SIMULATION_ANALYSIS_STATIC",
  "SIMULATION_ANALYSIS_MODELS" = "SIMULATION_ANALYSIS_MODELS",
  "SIMULATION_ANALYSIS_AB" = "SIMULATION_ANALYSIS_AB",
}

const nonExistingPages: CarModuleSectionCode[] = [
  CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_ANALYZE,
  CarModuleSectionCode.PLANNING_WORKBENCH,
  CarModuleSectionCode.SIMULATION_ANALYSIS_PRICE_DRIVEN,
  CarModuleSectionCode.SIMULATION_ANALYSIS_STATIC,
  CarModuleSectionCode.SIMULATION_ANALYSIS_MODELS,
  CarModuleSectionCode.SIMULATION_ANALYSIS_AB,
];

export enum CarModuleCode {
  // simulation
  "SIMULATION" = "SIMULATION",

  // ac analysis
  "CMPAS_ASSET_CLASS_ANALYSIS" = "CMPAS_ASSET_CLASS_ANALYSIS",

  // portfolio analysis
  "CMPAS_PORTFOLIO_ANALYSIS" = "CMPAS_PORTFOLIO_ANALYSIS",
  "CMPAS_PORTFOLIO_ANALYSIS_DATA_ENTRY" = "CMPAS_PORTFOLIO_ANALYSIS_DATA_ENTRY",
  "CMPAS_PORTFOLIO_OPTIMIZATION" = "CMPAS_PORTFOLIO_OPTIMIZATION",

  // asset allocation
  "CMPAS_ASSET_ALLOCATION" = "CMPAS_ASSET_ALLOCATION",
  "CMPAS_ASSET_ALLOCATION_DATA_ENTRY" = "CMPAS_ASSET_ALLOCATION_DATA_ENTRY",
  "CMPAS_ASSET_ALLOCATION_OPTIMIZER" = "CMPAS_ASSET_ALLOCATION_OPTIMIZER",
  "CMPAS_ASSET_ALLOCATION_PUBLISH" = "CMPAS_ASSET_ALLOCATION_PUBLISH",

  // planning
  "CMPAS_PLANNING_DATA_ENTRY" = "CMPAS_PLANNING_DATA_ENTRY",
  "CMPAS_PLANNING" = "CMPAS_PLANNING",
  "CMPAS_PLANNING_PUBLISH" = "CMPAS_PLANNING_PUBLISH",

  // settings
  "CMPAS_SETTINGS" = "CMPAS_SETTINGS",
  "CMPAS_BILLING" = "CMPAS_BILLING",
  "CMPAS_PAYMENT" = "CMPAS_PAYMENT",
  "CMPAS_DEFAULT_USERS" = "CMPAS_DEFAULT_USERS",
}

export const useUserAccess = () => {
  const selfAuth = useCommonAuthSelfRetrieveQuery();
  const roles = useRoles();
  const modules = useCommonCmpasModuleListQuery();
  const moduleSections = useCommonCmpasModuleSectionListQuery();

  return useMemo(() => {
    const isLoading =
      selfAuth.isLoading ||
      roles.isLoading ||
      modules.isLoading ||
      moduleSections.isLoading;

    const isCaravelUser = selfAuth.data?.firm?.id === CARAVEL_FIRM_ID;

    const isAdmin = roles.isAdminRole(selfAuth.data?.role);
    const role = roles.getRole(selfAuth.data?.role);

    const hasModuleAccess = (codes: CarModuleCode[]) => {
      if (codes.length) {
        return true; // todo replace this code
      }

      const moduleIds =
        modules.data
          ?.filter((i) => codes.includes((i.code ?? "") as CarModuleCode))
          .map((i) => i.id)
          .filter(isDefined) ?? [];

      return (
        isLoading ||
        isAdmin ||
        !!(
          role?.is_active &&
          role?.module_access?.some((ma) => moduleIds.includes(ma))
        )
      );
    };

    const sectionsWithAccess =
      moduleSections.data?.filter(
        (i) =>
          i.is_active &&
          role?.module_access?.some((ma) => ma === i.cmpas_module),
      ) ?? [];

    const hasModuleSectionAccess = (code: CarModuleSectionCode) => {
      if (nonExistingPages.includes(code)) {
        return true; // todo
      }

      return (
        isLoading ||
        isAdmin ||
        !!(
          role?.is_active &&
          moduleSections.data?.some(
            (i) =>
              i.code === code &&
              role?.module_access?.some((ma) => ma === i.cmpas_module),
          )
        )
      );
    };

    if (!isLoading) {
      if (isAdmin) {
        debugLog("User is Admin");
      } else {
        debugLog("User has access to:");
        debugTable(
          Array.from(sectionsWithAccess)
            .sort((a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0))
            .map((i) => ({
              code: i.code,
              name: i.name,
              // description: i.description,
              // url: i.front_end_url,
            })),
        );
      }
    }

    return {
      isLoading,
      hasModuleAccess,
      hasModuleSectionAccess,

      canAccessSimulation:
        isCaravelUser && hasModuleAccess([CarModuleCode.SIMULATION]),

      canAccessAcAnalysis: hasModuleAccess([
        CarModuleCode.CMPAS_ASSET_CLASS_ANALYSIS,
      ]),

      canAccessPortfolioAnalysis: hasModuleAccess([
        CarModuleCode.CMPAS_PORTFOLIO_ANALYSIS,
        CarModuleCode.CMPAS_PORTFOLIO_ANALYSIS_DATA_ENTRY,
        CarModuleCode.CMPAS_PORTFOLIO_OPTIMIZATION,
      ]),

      canAccessAssetAllocation: hasModuleAccess([
        CarModuleCode.CMPAS_ASSET_ALLOCATION,
        CarModuleCode.CMPAS_ASSET_ALLOCATION_DATA_ENTRY,
        CarModuleCode.CMPAS_ASSET_ALLOCATION_OPTIMIZER,
        CarModuleCode.CMPAS_ASSET_ALLOCATION_PUBLISH,
      ]),

      canAccessPlanning: hasModuleAccess([
        CarModuleCode.CMPAS_PLANNING,
        CarModuleCode.CMPAS_PLANNING_DATA_ENTRY,
        CarModuleCode.CMPAS_PLANNING_PUBLISH,
      ]),
    };
  }, [selfAuth, roles, modules, moduleSections]);
};

export type UseUserAccess = ReturnType<typeof useUserAccess>;
