import { Box, Typography } from "@mui/material";
import { PlanResults } from "./planResults/PlanResults";
import { CapitalGains } from "./capitalGains/CapitalGains";
import { useMount } from "app/useMount";
import { useAppDispatch } from "app/hooks";
import { api } from "api/carApi.manual";
import { StressTest } from "./stressTest/StressTest";
import { HouseholdAllocation } from "./householdAllocation/HouseholdAllocation";
import { AllocationByAccount } from "./allocationByAccount/AllocationByAccount";
import { usePlanTypes } from "app/usePlanTypes";
import { CarIconView } from "icons";
import { RenderErrorHandler } from "./RenderErrorHandler";
import { isDefined, openInNewTab } from "utils";
import { financialPlanningPages, rootPages } from "route.const";
import { useClientId, usePlanId, usePlanTypeId } from "app/usePlanId";
import {
  RootPage,
  RootPagePaperLink,
  RootPagePaperLinkBox,
  RootPageTransparentPaper,
} from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { useClientPlan } from "features/planResults/useClientPlan";
import { CarMenuButton } from "components/MenuButton";
import { PublishPlan } from "./publishPlan/PublishPlan";
import { CarMenuItem } from "components/MenuButton";
import { apiUrl } from "const";
import { useRender } from "app/useRender";
import { WorkbenchInflation } from "./WorkbenchInflation";
import {
  FlatPageRouteControl,
  FlatPageRouteTab,
} from "components/FlatPageRouteControl";
import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { controlPanelActions } from "app/controlPanelSlice";
import { useClientUpdateLastReviewed } from "app/useClientUpdateLastReviewed";
import { pendoClasses } from "app/thirdParty/pendo";
import { CarTooltipBox } from "components/TooltipBox";
import { TablePlaceHolder } from "components/PlaceHolder";
import { CarPageContent } from "components/PageContent";
import { CarPageContentCode, usePageContent } from "app/usePageContent";
import { useUser } from "pages/settings/useUser";
import { CarModuleSectionCode } from "app/useUserAccess";

const publishPlanPath = "publish-plan";

export const WorkbenchPage = () => {
  const pageContent = usePageContent();
  const planTypes = usePlanTypes();
  const clientPlan = useClientPlan();
  const planId = usePlanId();
  const clientId = useClientId();
  const planTypeId = usePlanTypeId();
  const dispatch = useAppDispatch();
  const { isCaravelUser } = useUser();
  const render = useRender();
  const navigate = useNavigate();
  useClientUpdateLastReviewed();

  const currentPageLabel = "Workbench";
  const appTitle = clientPlan.isLoading
    ? currentPageLabel
    : `${clientPlan.planName} - ${currentPageLabel}`;

  useMount(() => {
    dispatch(api.util.invalidateTags(["AlEngineRender"]));
    dispatch(controlPanelActions.reset());
  });

  const isLoading =
    pageContent.isLoading || planTypes.isLoading || clientPlan.isLoading;
  const isPlanPublished = !!clientPlan.publishedPlanTypeId;

  const draftUrl = isPlanPublished ? "" : `?draft=true`;

  const menuItems: CarMenuItem[] = [
    {
      label: isPlanPublished ? "View PDF" : "View Draft",
      icon: <CarIconView />,
      onClick: () => {
        if (clientId && planId && planTypeId) {
          openInNewTab(
            `${rootPages.getFinancialPlanningReportUrl({
              clientId,
              planId,
              planTypeId,
            })}${draftUrl}`,
          );
        }
      },
    },
  ];

  if (isCaravelUser) {
    menuItems.push(
      {
        label: "Input Schema",
        icon: <></>,
        onClick: () => {
          if (render.id && planTypeId) {
            openInNewTab(
              `${apiUrl}/api/al_engine/debug_input_json/${render.id}/${planTypeId}`,
            );
          }
        },
      },
      {
        label: "Output Results",
        icon: <></>,
        onClick: () => {
          if (render.id) {
            openInNewTab(
              `${apiUrl}/api/al_engine/debug_output_json/${render.id}`,
            );
          }
        },
      },
      {
        label: "View in Translucence",
        icon: <></>,
        onClick: () => {
          if (render.id) {
            openInNewTab(
              `${apiUrl}/api/_/legacy_html/translucence/overview/${render.id}`,
            );
          }
        },
      },
    );
  }

  let planTypeItems = planTypes.items;

  if (isPlanPublished) {
    planTypeItems = planTypeItems.filter(
      (i) => i.id === clientPlan.publishedPlanTypeId,
    );
  }

  useEffect(() => {
    if (isPlanPublished && planTypeId !== clientPlan.publishedPlanTypeId) {
      navigate(
        financialPlanningPages.getWorkbenchUrl(
          clientId,
          planId,
          clientPlan.publishedPlanTypeId ?? "",
        ),
      );
    }
  }, [
    clientId,
    clientPlan.publishedPlanTypeId,
    isPlanPublished,
    navigate,
    planId,
    planTypeId,
  ]);

  const selectedPlanLabel = planTypeItems.find((i) => i.id === planTypeId)
    ?.label;
  const selectedPlanIndex = planTypeItems.findIndex((i) => i.id === planTypeId);

  const headerLabel = isPlanPublished
    ? `${selectedPlanLabel} Portfolio Strategy`
    : `Option ${
        selectedPlanIndex + 1
      }: ${selectedPlanLabel} Portfolio Strategy`;

  const tabs = useMemo<Array<FlatPageRouteTab>>(() => {
    return [
      {
        url: "plan-results",
        label: "Plan Results",
        pendoClassName: pendoClasses.workbenchTabPlanResults,
        content: <PlanResults />,
        moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH_PLAN_RESULTS,
      },
      {
        url: "cap-gains",
        label: "Cap Gains",
        pendoClassName: pendoClasses.workbenchTabCapGains,
        content: <CapitalGains />,
        moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH_CAP_GAINS,
      },
      {
        url: "stress-test",
        label: "Stress Test",
        pendoClassName: pendoClasses.workbenchTabStressTest,
        content: <StressTest />,
        moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH_STRESS_TEST,
      },
      {
        url: "household-allocation",
        label: "Household Allocation",
        pendoClassName: pendoClasses.workbenchTabHouseholdAllocation,
        content: <HouseholdAllocation />,
        moduleSection:
          CarModuleSectionCode.PLANNING_WORKBENCH_HOUSEHOLD_ALLOCATION,
      },
      {
        url: "allocation-by-account",
        label: "Allocation by Account",
        pendoClassName: pendoClasses.workbenchTabAllocationByAccount,
        content: <AllocationByAccount />,
        moduleSection:
          CarModuleSectionCode.PLANNING_WORKBENCH_ALLOCATION_BY_ACCOUNT,
      },
      isPlanPublished
        ? undefined
        : {
            url: publishPlanPath,
            label: "Publish Plan",
            pendoClassName: pendoClasses.workbenchTabPublishPlan,
            content: <PublishPlan />,
            moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH_PUBLISH_PLAN,
          },
    ].filter(isDefined);
  }, [isPlanPublished]);

  return (
    <RootPage sx={{ backgroundColor: "white" }}>
      <RootPageHeader
        isLoading={clientPlan.isLoading}
        title={clientPlan.planName}
        appTitle={appTitle}
        items={[
          {
            label: "Client Summary",
            url: financialPlanningPages.getClientSummaryUrl(clientId),
            moduleSection: CarModuleSectionCode.PLANNING_CLIENT_SUMMARY,
          },
          {
            label: "Plan Input",
            url: financialPlanningPages.getPlanInputUrl(clientId, planId),
            disabled: clientPlan.isLoading || isPlanPublished,
            moduleSection: CarModuleSectionCode.PLANNING_PLAN_INPUT,
          },
          {
            label: "Workbench",
            url: financialPlanningPages.getWorkbenchUrl(
              clientId,
              planId,
              planTypeId ?? "",
            ),
            disabled: clientPlan.isLoading || isPlanPublished,
            moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH,
          },
          {
            label: "Progress Monitoring",
            url: financialPlanningPages.getProgressMonitoringUrl(clientId),
            moduleSection: CarModuleSectionCode.PLANNING_PROGRESS_MONITORING,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <TablePlaceHolder isLoading={isLoading} sx={{ mt: 5 }} rows={8}>
          <RootPagePaperLinkBox sx={{ width: "100%" }}>
            {planTypeItems.map((i) => (
              <RootPagePaperLink
                key={i.id}
                label={i.label}
                pendoClassName={pendoClasses.workbenchPlanResultType(i.code)}
                url={financialPlanningPages.getWorkbenchUrl(
                  clientId,
                  planId,
                  i.id ?? "",
                )}
              />
            ))}
            <>
              <WorkbenchInflation />
              <CarTooltipBox
                sx={{ position: "absolute", right: 6, top: 10 }}
                className={pendoClasses.workbenchInflation}
              />
            </>
          </RootPagePaperLinkBox>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              p: 3.75,
              pt: 5,
              position: "relative",
            }}
          >
            <Box
              sx={{
                mt: 1,
                display: "flex",
                alignItems: "center",
                gap: 1.5,
              }}
            >
              <Typography variant="h2SSemiBold">{headerLabel}</Typography>
              <CarMenuButton items={menuItems} />
            </Box>
            <CarPageContent
              sx={{ mt: 3 }}
              content={pageContent.getContent(
                CarPageContentCode.PLANNING_WORKBENCH_PLAN_RESULTS_LANDING_PAGE,
              )}
            />
            <FlatPageRouteControl sx={{ mt: 3 }} tabs={tabs} />
          </Box>

          <RenderErrorHandler isPlanPublished={isPlanPublished} />
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
