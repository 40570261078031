import { assetAllocationPages } from "route.const";
import { PortfolioGroupDashboardPage } from "./portfolioGroupList/PortfolioGroupDashboardPage";
import { EditPortfolioGroupPage } from "./editPortfolioGroup/EditPortfolioGroupPage";
import { ViewPortfolioGroupPage } from "./viewPortfolioGroup/ViewPortfolioGroupPage";
import { AccessRouteControl } from "components/AccessRouteControl";
import { CarModuleSectionCode } from "app/useUserAccess";

export const AssetAllocationPage = () => {
  return (
    <AccessRouteControl
      items={[
        {
          path: assetAllocationPages.dashboard,
          element: <PortfolioGroupDashboardPage />,
          moduleSection: CarModuleSectionCode.CMPAS_ASSET_ALLOCATION_DASHBOARD,
        },
        {
          path: assetAllocationPages.editPortfolioGroup,
          element: <EditPortfolioGroupPage />,
          // moduleSection: CarModuleSectionCode.EDIT_PORTFOLIO_GROUP, // todo
        },
        {
          path: assetAllocationPages.viewPortfolioGroup,
          element: <ViewPortfolioGroupPage />,
          moduleSection: CarModuleSectionCode.VIEW_PORTFOLIO_GROUP,
        },
      ]}
      defaultPath={assetAllocationPages.dashboard}
    />
  );
};
