import { assetClassAnalysisPages } from "route.const";
import { AssetClassAnalysisAnalyze } from "./AssetClassAnalysisAnalyze";
import { AssetClassAnalysisDashboard } from "./AssetClassAnalysisDashboard";
import { AccessRouteControl } from "components/AccessRouteControl";
import { CarModuleSectionCode } from "app/useUserAccess";

export const AssetClassAnalysisPage = () => {
  return (
    <AccessRouteControl
      items={[
        {
          path: assetClassAnalysisPages.dashboard,
          element: <AssetClassAnalysisDashboard />,
          moduleSection: CarModuleSectionCode.ASSET_CLASS_DASHBOARD,
        },
        {
          path: assetClassAnalysisPages.analyzePath,
          element: <AssetClassAnalysisAnalyze />,
          // moduleSection: CarModuleSectionCode.ASSET_CLASS_ANALYZE, // todo
        },
      ]}
      defaultPath={assetClassAnalysisPages.dashboard}
    />
  );
};
