import {
  AssetClassRegressionModelFe,
  RegressionField,
  useCmaAssetClassRegressionRetrieveQuery,
} from "api/carApi.generated";
import {
  ExcelColumn,
  ExcelDataFormat,
  ExcelTable,
  useExcelExport,
} from "app/useExcelExport";
import { useMemo } from "react";
import { formatDate } from "utils";
import { CarSimulationStatItem } from "./useSimulationReturns";
import { pendoClasses } from "app/thirdParty/pendo";

export interface AssetClassRegressionResult {
  x_label: string[];
  y_label: string[];
  x_data: number[];
  y_data: number[];
  data_dates?: string[];
  intercept: number;
  beta: number;
  beta2: number;
  residuals: number[];
  sigma: number;
  mu: number;
  r_squared: number;
}

interface RegressionResultItem {
  label: string;
  data: AssetClassRegressionResult;
  statItems: CarSimulationStatItem[];
}

interface UseSimulationModelsParams {
  assetClassId?: string;
  assetClassName?: string;
}

export const useSimulationModels = (params: UseSimulationModelsParams) => {
  const excelExport = useExcelExport();

  const data = useCmaAssetClassRegressionRetrieveQuery(
    {
      assetClassId: params.assetClassId ?? "",
    },
    {
      skip: !params.assetClassId,
    },
  );

  const response = data.data;

  return useMemo(() => {
    const getExcelTable = (
      name: string,
      items: RegressionField[],
    ): ExcelTable => {
      const rowKeys = Array.from(
        new Set(items.flatMap((i) => Object.keys(i.data))),
      ).sort((a, b) => a.localeCompare(b));
      return {
        name,
        columns: [
          {
            label: "",
            format: ExcelDataFormat.general,
            fraction: 1,
            charWidth: 14,
          },
          ...items.map<ExcelColumn>((i) => {
            return {
              label: i.name,
              pendoClassName: pendoClasses.simulationModelsModels(i.name),
              format:
                typeof Object.values(i.data).at(0) === "boolean"
                  ? ExcelDataFormat.boolean
                  : ExcelDataFormat.float,
              decimalOptions: {
                decimalPlaces: 6,
                forceShowDecimals: true,
              },
            };
          }),
        ],

        rows: rowKeys.map((key) => [
          formatDate(key),
          ...items.map((c) => c.data[key] ?? null),
        ]),
      };
    };

    const tables =
      response?.regression_input_data.map((i) =>
        getExcelTable(i.description, i.list_of_fields ?? []),
      ) ?? [];

    const handleDownloadReports = () => {
      excelExport.exportAllExcel({
        fileName: `Simulation Models - ${params.assetClassName}`,
        tables,
      });
    };

    const getStatItems = (
      value?: AssetClassRegressionModelFe,
    ): CarSimulationStatItem[] => [
      {
        label: "Beta",
        value: value?.beta,
        format: ExcelDataFormat.float,
        decimalOptions: {
          decimalPlaces: 6,
          forceShowDecimals: true,
        },
      },
      {
        label: "Y Intercept",
        value: value?.intercept,
        format: ExcelDataFormat.float,
        decimalOptions: {
          decimalPlaces: 6,
          forceShowDecimals: true,
        },
      },
      {
        label: "R Squared",
        value: value?.r_squared,
        format: ExcelDataFormat.float,
        decimalOptions: {
          decimalPlaces: 6,
          forceShowDecimals: true,
        },
      },
    ];

    const regressionResultItems: RegressionResultItem[] = Object.entries(
      (response?.regression_results ?? {}) as Record<
        string,
        AssetClassRegressionResult
      >, // todo fix in swagger
    ).map(([label, data]) => ({ label, data, statItems: getStatItems(data) }));

    return {
      isLoading: data.isLoading,
      tables,
      regressionStat: getStatItems(response?.model_stats),
      regressionResultItems,
      handleDownloadReports,
    };
  }, [response, data.isLoading, excelExport, params.assetClassName]);
};
