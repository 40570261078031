import { BaseDialog } from "components/BaseDialog";
import { PurchaseSeatsForm } from "./PurchaseSeatsForm";

interface AddSeatsDialogProps {
  onApply: (value: boolean) => void;
  onCancel: () => void;
}

export const AddSeatsDialog = (props: AddSeatsDialogProps) => {
  return (
    <BaseDialog sx={{ maxWidth: "100vw" }} onClose={props.onCancel}>
      <PurchaseSeatsForm
        onSuccess={() => {
          props.onApply(true);
        }}
        backButtonLabel="Cancel"
        onBack={props.onCancel}
      />
    </BaseDialog>
  );
};
