import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { editPortfolioPages } from "route.const";
import { usePortfolioGroupInfo } from "pages/assetAllocation/usePortfolioGroupInfo";
import { useOptimizationJobsPuller } from "pages/assetAllocation/editPortfolioGroup/useOptimizationJobsPuller";
import { EditPortfolioGroupAssetClassSelection } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupAssetClassSelection";
import { EditPortfolioGroupEditCompare } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupEditCompare";
import { EditPortfolioGroupCreateAllocations } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupCreateAllocations";
import { EditPortfolioGroupAnalyze } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupAnalyze";
import { EditPortfolioGroupOptimization } from "pages/assetAllocation/editPortfolioGroup/EditPortfolioGroupOptimization";
import { SourceDataAsOf } from "pages/assetAllocation/SourceDataAsOf";
import { CarModuleSectionCode } from "app/useUserAccess";
import { AccessRouteControl } from "components/AccessRouteControl";

export const EditPortfolioPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  useOptimizationJobsPuller(portfolioGroupVersionId);

  return (
    <RootPage sx={{ backgroundColor: "white", paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`Portfolio Analysis - ${groupInfo.title}`}
        isLoading={groupInfo.isLoading}
        items={[
          {
            label: "Asset Class Selection",
            url: editPortfolioPages.assetClassSelection,
            moduleSection:
              CarModuleSectionCode.PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION,
          },
          {
            label: "Create Model",
            url: editPortfolioPages.createModel,
            moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_CREATE_MODEL,
          },
          {
            label: "Optimization",
            url: editPortfolioPages.optimization,
            hidden: !groupInfo.isPublished,
            moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_OPTIMIZATION,
          },
          {
            label: "Edit/Compare",
            url: editPortfolioPages.editCompare,
            hidden: !groupInfo.isPublished,
            moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_EDIT_COMPARE,
          },
          {
            label: "Analyze",
            url: editPortfolioPages.analyze,
            hidden: !groupInfo.isPublished,
            moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_ANALYZE,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <AccessRouteControl
          items={[
            {
              path: editPortfolioPages.assetClassSelection,
              element: <EditPortfolioGroupAssetClassSelection />,
              moduleSection:
                CarModuleSectionCode.PORTFOLIO_ANALYSIS_ASSET_CLASS_SELECTION,
            },
            {
              path: editPortfolioPages.createModel,
              element: <EditPortfolioGroupCreateAllocations />,
              moduleSection:
                CarModuleSectionCode.PORTFOLIO_ANALYSIS_CREATE_MODEL,
            },
            {
              path: editPortfolioPages.optimization,
              element: <EditPortfolioGroupOptimization />,
              moduleSection:
                CarModuleSectionCode.PORTFOLIO_ANALYSIS_OPTIMIZATION,
            },
            {
              path: editPortfolioPages.editCompare,
              element: <EditPortfolioGroupEditCompare />,
              moduleSection:
                CarModuleSectionCode.PORTFOLIO_ANALYSIS_EDIT_COMPARE,
            },
            {
              path: editPortfolioPages.analyze,
              element: <EditPortfolioGroupAnalyze />,
              moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_ANALYZE,
            },
          ]}
          defaultPath={editPortfolioPages.assetClassSelection}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
