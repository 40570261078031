import { CarModuleSectionCode, useUserAccess } from "app/useUserAccess";
import { ReactElement } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

export interface AccessRouteItem {
  path: string;
  element: ReactElement;
  moduleSection?: CarModuleSectionCode;
  hidden?: boolean;
}

interface AccessRouteControlProps {
  items: AccessRouteItem[];
  defaultPath?: string;
}

export const AccessRouteControl = ({
  items,
  defaultPath,
}: AccessRouteControlProps) => {
  const userAccess = useUserAccess();

  const filteredItems = items.filter(
    (i) =>
      !i.hidden &&
      (!i.moduleSection || userAccess.hasModuleSectionAccess(i.moduleSection)),
  );
  const defaultTo = defaultPath ?? filteredItems.at(0)?.path;

  return (
    <Routes>
      {filteredItems.map((i) => (
        <Route key={i.path} path={i.path} element={i.element} />
      ))}
      {defaultTo && (
        <Route path="*" element={<Navigate to={defaultTo} replace />} />
      )}
    </Routes>
  );
};
