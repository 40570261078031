import { useEffect } from "react";
import {
  RootPage,
  RootPageBox,
  RootPageTransparentPaper,
} from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { PortfolioGroupComparison } from "./PortfolioGroupComparison";
import { PortfolioGroupSummary } from "./PortfolioGroupSummary";
import { PortfolioGroupVersions } from "./PortfolioGroupVersions";
import { SelectPortfolioGroup } from "./SelectPortfolioGroup";
import { assetAllocationPages, rootPages } from "route.const";
import { usePortfolioGroupList } from "./usePortfolioGroupList";
import { useLocalStorage } from "features/general/useLocalStorage";
import { useNavigate, useSearchParams } from "react-router-dom";
import { TablePlaceHolder } from "components/PlaceHolder";
import { Box, Typography } from "@mui/material";
import { PortfolioGroup } from "api/carApi.generated";
import { AddPortfolioGroup } from "./AddPortfolioGroup";
import { usePortfolioGroupSummary } from "./usePortfolioGroupSummary";
import { CarModuleSectionCode } from "app/useUserAccess";

const PortfolioIcon = () => (
  <svg width="112" height="92" viewBox="0 0 112 92" fill="none">
    <mask id="path-1-inside-1_10238_4232" fill="white">
      <path d="M106.081 52.8141V86.7599H5.23856V53.2332C17.0986 58.0369 33.7939 62.5735 54.5753 62.5735C76.4463 62.5735 94.4774 57.3769 106.081 52.8193M111.319 44.8672C100.538 49.9433 80.4014 57.335 54.5753 57.335C28.7492 57.335 10.3147 50.2315 0 45.1553V87.5981C0 90.0288 1.9697 91.9985 4.40039 91.9985H106.919C109.35 91.9985 111.319 90.0288 111.319 87.5981V44.8672Z" />
    </mask>
    <path
      d="M106.081 52.8141V86.7599H5.23856V53.2332C17.0986 58.0369 33.7939 62.5735 54.5753 62.5735C76.4463 62.5735 94.4774 57.3769 106.081 52.8193M111.319 44.8672C100.538 49.9433 80.4014 57.335 54.5753 57.335C28.7492 57.335 10.3147 50.2315 0 45.1553V87.5981C0 90.0288 1.9697 91.9985 4.40039 91.9985H106.919C109.35 91.9985 111.319 90.0288 111.319 87.5981V44.8672Z"
      fill="#9FA0A1"
    />
    <path
      d="M106.081 86.7599V87.7599H107.081V86.7599H106.081ZM5.23856 86.7599H4.23856V87.7599H5.23856V86.7599ZM5.23856 53.2332L5.61397 52.3063L4.23856 51.7492V53.2332H5.23856ZM111.319 44.8672H112.319V43.291L110.893 43.9625L111.319 44.8672ZM0 45.1553L0.441554 44.2581L-1 43.5486V45.1553H0ZM105.081 52.8141V86.7599H107.081V52.8141H105.081ZM106.081 85.7599H5.23856V87.7599H106.081V85.7599ZM6.23856 86.7599V53.2332H4.23856V86.7599H6.23856ZM4.86315 54.16C16.8233 59.0043 33.646 63.5735 54.5753 63.5735V61.5735C33.9418 61.5735 17.374 57.0695 5.61397 52.3063L4.86315 54.16ZM54.5753 63.5735C76.6027 63.5735 94.7597 58.3404 106.446 53.7501L105.715 51.8885C94.1951 56.4134 76.2899 61.5735 54.5753 61.5735V63.5735ZM110.893 43.9625C100.202 48.9963 80.2126 56.335 54.5753 56.335V58.335C80.5901 58.335 100.875 50.8904 111.745 45.7719L110.893 43.9625ZM54.5753 56.335C28.9402 56.335 10.6575 49.2856 0.441554 44.2581L-0.441554 46.0525C9.97194 51.1773 28.5582 58.335 54.5753 58.335V56.335ZM-1 45.1553V87.5981H1V45.1553H-1ZM-1 87.5981C-1 90.5811 1.41741 92.9985 4.40039 92.9985V90.9985C2.52198 90.9985 1 89.4765 1 87.5981H-1ZM4.40039 92.9985H106.919V90.9985H4.40039V92.9985ZM106.919 92.9985C109.902 92.9985 112.319 90.5811 112.319 87.5981H110.319C110.319 89.4765 108.797 90.9985 106.919 90.9985V92.9985ZM112.319 87.5981V44.8672H110.319V87.5981H112.319Z"
      fill="#9FA0A1"
      mask="url(#path-1-inside-1_10238_4232)"
    />
    <mask id="path-3-inside-2_10238_4232" fill="white">
      <path d="M106.081 22.2985V46.3906C95.8446 51.3253 77.5359 58.1459 54.5753 58.1459C36.1932 58.1459 21.5095 53.7193 12.4259 50.0104C9.93754 48.9942 7.53304 47.8941 5.23856 46.7311V22.2932H106.086M106.804 17.0547H4.52087C2.02208 17.0547 0 19.1815 0 21.8113V49.8795C2.65071 51.3463 6.16054 53.1064 10.4457 54.8561C19.9641 58.7431 35.3445 63.3845 54.5805 63.3845C81.1086 63.3845 101.733 54.6151 111.325 49.6228V21.8113C111.325 19.1868 109.297 17.0547 106.804 17.0547Z" />
    </mask>
    <path
      d="M106.081 22.2985V46.3906C95.8446 51.3253 77.5359 58.1459 54.5753 58.1459C36.1932 58.1459 21.5095 53.7193 12.4259 50.0104C9.93754 48.9942 7.53304 47.8941 5.23856 46.7311V22.2932H106.086M106.804 17.0547H4.52087C2.02208 17.0547 0 19.1815 0 21.8113V49.8795C2.65071 51.3463 6.16054 53.1064 10.4457 54.8561C19.9641 58.7431 35.3445 63.3845 54.5805 63.3845C81.1086 63.3845 101.733 54.6151 111.325 49.6228V21.8113C111.325 19.1868 109.297 17.0547 106.804 17.0547Z"
      fill="#9FA0A1"
    />
    <path
      d="M106.081 46.3906L106.515 47.2914L107.081 47.0187V46.3906H106.081ZM12.4259 50.0104L12.0478 50.9362L12.0478 50.9362L12.4259 50.0104ZM5.23856 46.7311H4.23856V47.3454L4.78646 47.6231L5.23856 46.7311ZM5.23856 22.2932V21.2932H4.23856V22.2932H5.23856ZM0 49.8795H-1V50.469L-0.484174 50.7545L0 49.8795ZM10.4457 54.8561L10.8237 53.9303L10.8237 53.9303L10.4457 54.8561ZM111.325 49.6228L111.786 50.5098L112.325 50.2297V49.6228H111.325ZM105.081 22.2985V46.3906H107.081V22.2985H105.081ZM105.647 45.4898C95.4988 50.3819 77.3408 57.1459 54.5753 57.1459V59.1459C77.731 59.1459 96.1905 52.2687 106.515 47.2914L105.647 45.4898ZM54.5753 57.1459C36.3531 57.1459 21.7992 52.7575 12.8039 49.0846L12.0478 50.9362C21.2199 54.6812 36.0333 59.1459 54.5753 59.1459V57.1459ZM12.804 49.0847C10.3406 48.0786 7.96081 46.9898 5.69065 45.8391L4.78646 47.6231C7.10528 48.7984 9.53447 49.9097 12.0478 50.9362L12.804 49.0847ZM6.23856 46.7311V22.2932H4.23856V46.7311H6.23856ZM5.23856 23.2932H106.086V21.2932H5.23856V23.2932ZM106.804 16.0547H4.52087V18.0547H106.804V16.0547ZM4.52087 16.0547C1.4226 16.0547 -1 18.6777 -1 21.8113H1C1 19.6854 2.62157 18.0547 4.52087 18.0547V16.0547ZM-1 21.8113V49.8795H1V21.8113H-1ZM-0.484174 50.7545C2.19705 52.2381 5.74232 54.0158 10.0677 55.7819L10.8237 53.9303C6.57876 52.1971 3.10437 50.4544 0.484174 49.0045L-0.484174 50.7545ZM10.0676 55.7819C19.6742 59.7049 35.1844 64.3845 54.5805 64.3845V62.3845C35.5047 62.3845 20.2541 57.7814 10.8237 53.9303L10.0676 55.7819ZM54.5805 64.3845C81.3236 64.3845 102.109 55.5464 111.786 50.5098L110.863 48.7358C101.356 53.6838 80.8935 62.3845 54.5805 62.3845V64.3845ZM112.325 49.6228V21.8113H110.325V49.6228H112.325ZM112.325 21.8113C112.325 18.6829 109.897 16.0547 106.804 16.0547V18.0547C108.698 18.0547 110.325 19.6907 110.325 21.8113H112.325Z"
      fill="#9FA0A1"
      mask="url(#path-3-inside-2_10238_4232)"
    />
    <path
      d="M59.4358 52.4609H51.8818C50.8576 52.4609 50.0273 53.2912 50.0273 54.3154V67.9147C50.0273 68.9389 50.8576 69.7691 51.8818 69.7691H59.4358C60.46 69.7691 61.2902 68.9389 61.2902 67.9147V54.3154C61.2902 53.2912 60.46 52.4609 59.4358 52.4609Z"
      fill="white"
      stroke="#9FA0A1"
      strokeWidth="4"
      strokeMiterlimit="10"
    />
    <path
      d="M34.5136 16.562C34.6311 11.5134 36.5378 7.57172 39.9821 4.85727C43.5563 2.0405 48.8446 0.5 55.6588 0.5C62.4729 0.5 67.7612 2.0405 71.3354 4.85727C74.7798 7.57172 76.6864 11.5134 76.8039 16.562H72.5635C72.4921 14.2665 71.9269 12.3753 70.9865 10.8307C69.9793 9.17629 68.5585 7.94723 66.9123 7.04601C63.6349 5.25181 59.4309 4.73856 55.6588 4.73856C51.8864 4.73856 47.6825 5.25315 44.4052 7.04801C42.759 7.94955 41.3383 9.17875 40.331 10.8328C39.3906 12.3772 38.8255 14.2677 38.7541 16.562H34.5136Z"
      fill="#9FA0A1"
      stroke="#9FA0A1"
    />
  </svg>
);

export const PortfolioGroupDashboardPage = () => {
  const [selectedPortfolioGroupId, setSelectedPortfolioGroupId] =
    useLocalStorage<string | undefined>(
      "PortfolioGroupDashboardPage_selectedPortfolioGroupId",
      undefined,
    );

  const portfolioGroupList = usePortfolioGroupList();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const portfolioGroupIdParam = searchParams.get("portfolioGroupId") ?? "";

  useEffect(() => {
    if (portfolioGroupIdParam) {
      setSelectedPortfolioGroupId(portfolioGroupIdParam);
      navigate(rootPages.assetAllocationUrl);
    }
  }, [portfolioGroupIdParam, setSelectedPortfolioGroupId, navigate]);

  useEffect(() => {
    if (
      portfolioGroupList.items.length > 0 &&
      !portfolioGroupList.items.some((i) => i.id === selectedPortfolioGroupId)
    ) {
      const activeItemId = portfolioGroupList.items.find((i) => i.is_active)
        ?.id;
      if (activeItemId) {
        setSelectedPortfolioGroupId(activeItemId);
      } else {
        setSelectedPortfolioGroupId(
          portfolioGroupList.items[0]?.id ?? undefined,
        );
      }
    }
  }, [
    selectedPortfolioGroupId,
    portfolioGroupList,
    setSelectedPortfolioGroupId,
  ]);

  const portfolioGroupSummary = usePortfolioGroupSummary(
    selectedPortfolioGroupId,
  );

  const handlePortfolioGroupCreated = async (group: PortfolioGroup) => {
    const portfolioGroupId = group.id;

    if (!portfolioGroupId) {
      return;
    }

    setSelectedPortfolioGroupId(portfolioGroupId);

    navigate(
      assetAllocationPages.getEditPortfolioGroupUrl({
        portfolioGroupId,
        isPublished: false,
      }),
    );
  };

  return (
    <RootPage sx={{ paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title="Asset Allocation"
        items={[
          {
            label: "Dashboard",
            url: "#", // assetAllocationPages.dashboard,
            moduleSection:
              CarModuleSectionCode.CMPAS_ASSET_ALLOCATION_DASHBOARD,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <TablePlaceHolder
          isLoading={portfolioGroupList.isLoading}
          sx={{ mx: 7, mt: 10 }}
          rows={8}
        >
          <Typography variant="h2SSemiBold" sx={{ mt: 3 }}>
            Dashboard
          </Typography>
          <Box
            sx={{
              mt: 3,
              flex: "auto",
              display: "grid",
              gridTemplateColumns: "repeat(10, 1fr)",
              gridTemplateRows:
                portfolioGroupList.items.length === 0
                  ? "min-content auto"
                  : "min-content min-content",
              gap: 4,
            }}
          >
            <SelectPortfolioGroup
              sx={{ gridColumn: "1 / span 4", height: 102 }}
              portfolioGroupList={portfolioGroupList}
              selectedPortfolioGroupId={selectedPortfolioGroupId}
              onChange={setSelectedPortfolioGroupId}
            />
            <AddPortfolioGroup
              sx={{ gridColumn: "5 / span 6", height: 102 }}
              onPortfolioGroupCreated={handlePortfolioGroupCreated}
            />

            {portfolioGroupList.items.length > 0 ? (
              <>
                <PortfolioGroupSummary
                  sx={{ gridColumn: "1 / span 5", gridRow: "2 / span 2" }}
                  portfolioGroupSummary={portfolioGroupSummary}
                />
                <PortfolioGroupVersions
                  sx={{ gridColumn: "6 / span 5" }}
                  selectedPortfolioGroupId={selectedPortfolioGroupId}
                />
                <PortfolioGroupComparison
                  sx={{ gridColumn: "6 / span 5" }}
                  portfolioGroupSummary={portfolioGroupSummary}
                />
              </>
            ) : (
              <RootPageBox sx={{ gridColumn: "1 / span 10" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    m: "auto",
                    alignItems: "center",
                  }}
                >
                  <PortfolioIcon />
                  <Typography variant="h4SBold" sx={{ mt: 4, fontSize: 25 }}>
                    You haven’t added a portfolio group yet
                  </Typography>
                </Box>
              </RootPageBox>
            )}
          </Box>
        </TablePlaceHolder>
      </RootPageTransparentPaper>
    </RootPage>
  );
};
