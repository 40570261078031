import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { usePortfolioGroupInfo } from "../usePortfolioGroupInfo";
import { EditPortfolioGroupAssetClassSelection } from "./EditPortfolioGroupAssetClassSelection";
import { EditPortfolioGroupEditCompare } from "./EditPortfolioGroupEditCompare";
import { EditPortfolioGroupPublish } from "./EditPortfolioGroupPublish";
import { usePortfolioGroupParams } from "app/usePortfolioGroupParams";
import { useOptimizationJobsPuller } from "./useOptimizationJobsPuller";
import { editPortfolioGroupPages } from "route.const";
import { EditPortfolioGroupCreateAllocations } from "./EditPortfolioGroupCreateAllocations";
import { EditPortfolioGroupSelection } from "./EditPortfolioGroupSelection";
import { EditPortfolioGroupAnalyze } from "./EditPortfolioGroupAnalyze";
import { EditPortfolioGroupOptimization } from "./EditPortfolioGroupOptimization";
import { SourceDataAsOf } from "../SourceDataAsOf";
import { CarModuleSectionCode } from "app/useUserAccess";
import { AccessRouteControl } from "components/AccessRouteControl";

export const EditPortfolioGroupPage = () => {
  const { portfolioGroupId } = usePortfolioGroupParams();
  const groupInfo = usePortfolioGroupInfo({ portfolioGroupId });
  const { portfolioGroupVersionId } = groupInfo;

  useOptimizationJobsPuller(portfolioGroupVersionId);

  return (
    <RootPage sx={{ backgroundColor: "white", paddingBottom: 0 }}>
      <RootPageHeader
        isSmall
        title={`Asset Allocation - ${groupInfo.title}`}
        isLoading={groupInfo.isLoading}
        items={[
          {
            label: "Asset Class Selection",
            url: editPortfolioGroupPages.assetClassSelection,
            moduleSection:
              CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS,
          },
          {
            label: "Create Allocations",
            url: editPortfolioGroupPages.createAllocations,
            moduleSection:
              CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION,
          },
          {
            label: "Optimization",
            url: editPortfolioGroupPages.optimization,
            moduleSection:
              CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_OPTIMIZER,
            hidden: !groupInfo.isPublished,
          },
          {
            label: "Edit/Compare",
            url: editPortfolioGroupPages.editCompare,
            moduleSection:
              CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE,
            hidden: !groupInfo.isPublished,
          },
          {
            label: "Analyze",
            url: editPortfolioGroupPages.analyze,
            moduleSection: CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_ANALYZE,
            hidden: !groupInfo.isPublished,
          },
          {
            label: "Selection",
            url: editPortfolioGroupPages.selection,
            moduleSection:
              CarModuleSectionCode.PORTFOLIO_GROUP_PUBLISH_SELECTION,
            hidden: !groupInfo.isPublished,
          },
          // {
          //   label: "Reports",
          //   url: editPortfolioGroupPages.reportsUrl,
          //   hidden: !groupInfo.isPublished,
          // },
          {
            label: "Publish",
            url: editPortfolioGroupPages.publish,
            moduleSection:
              CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS,
            hidden: !groupInfo.isPublished,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <AccessRouteControl
          items={[
            {
              path: editPortfolioGroupPages.assetClassSelection,
              element: <EditPortfolioGroupAssetClassSelection />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_SELECT_ASSET_CLASS,
            },
            {
              path: editPortfolioGroupPages.createAllocations,
              element: <EditPortfolioGroupCreateAllocations />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_CREATE_ALLOCATION,
            },
            {
              path: editPortfolioGroupPages.optimization,
              element: <EditPortfolioGroupOptimization />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_OPTIMIZER,
            },
            {
              path: editPortfolioGroupPages.editCompare,
              element: <EditPortfolioGroupEditCompare />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_EDIT_COMPARE,
            },
            {
              path: editPortfolioGroupPages.analyze,
              element: <EditPortfolioGroupAnalyze />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_ANALYZE,
            },
            {
              path: editPortfolioGroupPages.selection,
              element: <EditPortfolioGroupSelection />,
              moduleSection:
                CarModuleSectionCode.PORTFOLIO_GROUP_PUBLISH_SELECTION,
            },
            // {
            //   path: editPortfolioGroupPages.reportsPath,
            //   element: <EditPortfolioGroupReports />,
            // },
            {
              path: editPortfolioGroupPages.publish,
              element: <EditPortfolioGroupPublish />,
              moduleSection:
                CarModuleSectionCode.ANALYZE_PORTFOLIO_GROUP_PUBLISHED_PORTFOLIOS,
            },
          ]}
          defaultPath={editPortfolioGroupPages.assetClassSelection}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
