import { Box, Typography, styled, useTheme } from "@mui/material";
import { NavLink } from "react-router-dom";
import { CarModuleSectionCode, useUserAccess } from "app/useUserAccess";
import { CarTooltipBox } from "./TooltipBox";
import { useAppTitle } from "app/useAppTitle";
import { CarBurgerMenuButton } from "./BurgerMenuButton";
import headerBackground from "assets/header-background.jpg";
import { UserBadge } from "app/UserBadge";

const StyledLink = styled(NavLink)({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
});

interface RootPageHeaderLinkProps {
  className?: string;
  pendoClassName?: string;
  label: string;
  url: string;
  id?: string;
  end?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: React.HTMLAttributeAnchorTarget;
  disabled?: boolean;
}

const RootPageHeaderLink = (props: RootPageHeaderLinkProps) => {
  return (
    <StyledLink
      onClick={props.disabled ? (e) => e.preventDefault() : props.onClick}
      to={props.url}
      end={props.end}
      target={props.target}
    >
      {({ isActive }) => (
        <>
          <Typography
            variant="h5SMedium"
            sx={{
              color: "white",
              cursor: props.disabled ? "default" : "pointer",
              position: "relative",
              // px: 1,
              pb: 0.4,
              borderBottom: "solid 2px",
              borderBottomColor: isActive ? "white" : "rgba(0, 0, 0, 0)",
              opacity: props.disabled ? 0.7 : 1,
              textAlign: "center",
              userSelect: "none",
            }}
          >
            {props.label}
          </Typography>
          {props.pendoClassName && (
            <CarTooltipBox
              sx={{ position: "absolute", right: -4, top: 8 }}
              className={props.pendoClassName}
            />
          )}
        </>
      )}
    </StyledLink>
  );
};

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flexShrink: "0",
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPositionX: "right",
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  paddingTop: theme.spacing(5.5),
  minHeight: 200,

  "&.is-small": {
    minHeight: 159,
  },

  ".title-row": {
    display: "flex",
    alignItems: "center",
    filter: "drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.15))",
    gap: theme.spacing(1),
  },
  ".title": {
    color: theme.palette.white,
    [theme.breakpoints.up("xl")]: {
      marginLeft: theme.spacing(2),
    },
  },
}));

export interface RootPageHeaderLinkItem {
  url: string;
  label: string;
  disabled?: boolean;
  hidden?: boolean;
  moduleSection?: CarModuleSectionCode;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}

export interface RootPageHeaderProps {
  isLoading?: boolean;
  title: string;
  appTitle?: string;
  titleActions?: JSX.Element;
  items?: RootPageHeaderLinkItem[];
  isTransparent?: Boolean;
  isSmall?: boolean; // todo remove when header look is unified
}

export const RootPageHeader = (props: RootPageHeaderProps) => {
  useAppTitle(props.appTitle ?? props.title);

  const theme = useTheme();
  const userAccess = useUserAccess();

  const filteredLinks =
    props.items?.filter(
      (i) =>
        !i.hidden &&
        (!i.moduleSection ||
          userAccess.hasModuleSectionAccess(i.moduleSection)),
    ) ?? [];

  return (
    <StyledHeader
      sx={{
        backgroundColor: props.isTransparent ? undefined : `primary.main`,
        backgroundImage: props.isTransparent
          ? undefined
          : `url(${headerBackground})`,
        position: "relative",
      }}
      className={props.isSmall ? "is-small" : undefined}
    >
      <UserBadge />
      <Box className="title-row">
        <CarBurgerMenuButton />
        <Typography
          className="title"
          variant="h3SMedium"
          sx={{ userSelect: "none" }}
        >
          {props.isLoading ? "Loading..." : props.title}
        </Typography>
        {!props.isLoading && props.titleActions}
      </Box>
      <Box
        sx={{
          mt: 3,
          ml: 2,
          display: "flex",
          gap: 5,

          [theme.breakpoints.down("xl")]: {
            gap: 3,
          },
        }}
      >
        {!props.isLoading &&
          !userAccess.isLoading &&
          filteredLinks.map((i) => (
            <RootPageHeaderLink
              key={i.url}
              label={i.label}
              url={i.url}
              disabled={i.disabled}
              onClick={i.onClick}
            />
          ))}
      </Box>
    </StyledHeader>
  );
};
