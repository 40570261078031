import { financialPlanningPages } from "route.const";
import { AddClientPage } from "./addClient/AddClientPage";
import { ClientListPage } from "./clientList/ClientListPage";
import { ClientSummaryPage } from "./clientSummary/ClientSummaryPage";
import { PlanInputPage } from "./planInput/PlanInputPage";
import { WorkbenchPage } from "./workbench/WorkbenchPage";
import { ProgressMonitoringPage } from "./progressMonitoring/ProgressMonitoringPage";
import { AccessRouteControl } from "components/AccessRouteControl";
import { CarModuleSectionCode } from "app/useUserAccess";

export const FinancialPlanningPage = () => {
  return (
    <AccessRouteControl
      items={[
        {
          path: financialPlanningPages.clientListPath,
          element: <ClientListPage />,
          moduleSection: CarModuleSectionCode.PLANNING_CLIENT_LIST,
        },
        {
          path: financialPlanningPages.addClientPath,
          element: <AddClientPage />,
          moduleSection: CarModuleSectionCode.PLANNING_ADD_CLIENT,
        },

        {
          path: financialPlanningPages.clientSummaryPath,
          element: <ClientSummaryPage />,
          moduleSection: CarModuleSectionCode.PLANNING_CLIENT_SUMMARY,
        },
        {
          path: financialPlanningPages.planInputPath,
          element: <PlanInputPage />,
          moduleSection: CarModuleSectionCode.PLANNING_PLAN_INPUT,
        },
        {
          path: financialPlanningPages.workbenchPath,
          element: <WorkbenchPage />,
          // moduleSection: CarModuleSectionCode.PLANNING_WORKBENCH,
        },
        {
          path: financialPlanningPages.progressMonitoringPath,
          element: <ProgressMonitoringPage />,
          moduleSection: CarModuleSectionCode.PLANNING_PROGRESS_MONITORING,
        },
      ]}
      defaultPath={financialPlanningPages.clientListPath}
    />
  );
};
