import React from "react";
import { Box, rgbToHex, Typography, useTheme } from "@mui/material";
import { LoginLogo, PayButton } from "../login/LoginComponents";

import { PaymentElement, useElements, Elements } from "@stripe/react-stripe-js";

import { formatCurrency } from "utils";
import { usePayment, UsePayment } from "./usePayment";
import { TablePlaceHolder } from "components/PlaceHolder";

interface InternalFormProps {
  amount: number;
  clientSecret: string;
  onSkipPayment?: () => void;
  onBack?: () => void;
  data: UsePayment;
}

const InternalForm = (props: InternalFormProps) => {
  const elements = useElements();

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (
    event,
  ) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (elements) {
      props.data.handlePay(elements);
    }
  };

  return (
    <>
      <LoginLogo sx={{ mb: 3 }} />
      <Typography variant="h1SSemiBold">Payment</Typography>
      <Box
        onSubmit={handleSubmit}
        component="form"
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <Typography
          variant="h1SSemiBold"
          sx={{ fontSize: "15px", color: "gray6" }}
        >
          Enter your payment details below to purchase.
        </Typography>
        <PaymentElement options={props.data.paymentElementOptions} />
        <Box
          sx={{
            mt: 7,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Typography variant="h6SemiBold">{`Total Amount: ${formatCurrency(
            props.amount,
          )}`}</Typography>
          <Box sx={{ display: "flex", gap: 3.5 }}>
            {props.onBack && (
              <PayButton
                sx={{
                  mt: 2,
                  width: 248,
                  alignSelf: "center",
                }}
                variant="outlined"
                onClick={props.onBack}
              >
                Back
              </PayButton>
            )}
            <PayButton
              isLoading={props.data.isPaying}
              sx={{
                mt: 2,
                width: 248,
                alignSelf: "center",
              }}
              type="submit"
            >
              Pay for full access
            </PayButton>
            {props.onSkipPayment && (
              <PayButton
                sx={{
                  mt: 2,
                  width: 248,
                  alignSelf: "center",
                }}
                variant="outlined"
                onClick={props.onSkipPayment}
              >
                Skip for now
              </PayButton>
            )}
          </Box>
          <Typography
            variant="par01Regular"
            sx={{ mt: 2, fontWeight: 700, fontSize: "12px" }}
          >
            This payment is Safe And Secure
          </Typography>
        </Box>
      </Box>
    </>
  );
};

interface PaymentFormProps {
  amount: number;
  clientSecret: string;
  onSkipPayment?: () => void;
  onBack?: () => void;
  isWhiteContext?: boolean;
}

export const PaymentForm = (props: PaymentFormProps) => {
  const theme = useTheme();

  const data = usePayment();

  return (
    <TablePlaceHolder
      sx={{ minWidth: 524 }}
      isLoading={data.isLoading}
      rows={8}
    >
      <Elements
        stripe={data.stripePromise ?? null}
        options={{
          clientSecret: props.clientSecret,
          appearance: {
            theme: "stripe",
            variables: {
              fontFamily: theme.typography.fontFamily,
              spacingGridRow: "16px",
              colorPrimary: rgbToHex(theme.palette.caravelOrangePrimary),
              colorBackground: rgbToHex(
                props.isWhiteContext
                  ? theme.palette.gray1
                  : theme.palette.white,
              ),
              colorText: rgbToHex(theme.palette.softBlack),
              colorDanger: rgbToHex(theme.palette.error.main),
              colorTextPlaceholder: rgbToHex(theme.palette.gray7),
              ...{ colorBackgroundDeemphasize10: "red" as any },
            },
            rules: {
              ".Input": {
                border: `solid 2px ${
                  props.isWhiteContext
                    ? theme.palette.gray3
                    : theme.palette.caravelOrangePrimary
                }`,
              },

              ".Input:focus": {
                outline: "unset",
                borderColor: theme.palette.caravelOrangePrimary,
                boxShadow: "unset",
              },
              ".Input--invalid": {
                boxShadow: "unset",
              },
            },
          },
        }}
      >
        <InternalForm data={data} {...props} />
      </Elements>
    </TablePlaceHolder>
  );
};
