import { useEffect, useRef, useState } from "react";
import { TransitionGroup } from "react-transition-group";
import { TablePlaceHolder } from "components/PlaceHolder";
import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { CarNotification, useNotifications } from "./useNotifications";
import { notificationsPage } from "route.const";
import { Box, Collapse, Typography, styled } from "@mui/material";
import { NotificationCard } from "./NotificationCard";
import { isToday, isYesterday } from "date-fns";
import { Confetti } from "./Confetti";
import { AccessRouteControl } from "components/AccessRouteControl";

interface NotificationListProps {
  label: string;
  isLoading: boolean;
  items: CarNotification[];
  onClearClick: (item: CarNotification) => void;
  onSnooze: (item: CarNotification, snoozeTime: string) => void;
  showConfetti?: boolean;
}

const StyledGroup = styled(TransitionGroup)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "stretch",
}));

const NotificationList = (props: NotificationListProps) => {
  const todayItems = props.items.filter((i) => isToday(i.date));
  const yesterdayItems = props.items.filter((i) => isYesterday(i.date));
  const previousItems = props.items.filter(
    (i) => !isToday(i.date) && !isYesterday(i.date),
  );

  const renderNotification = (notification: CarNotification) => (
    <Collapse key={notification.id}>
      <NotificationCard
        sx={{ mt: 5 }}
        value={notification}
        onClearClick={() => props.onClearClick(notification)}
        onSnooze={(snoozeTime) => props.onSnooze(notification, snoozeTime)}
      />
    </Collapse>
  );

  const renderItems = (items: CarNotification[], label: string) => {
    if (items.length === 0) {
      return null;
    }
    return (
      <>
        <Typography sx={{ mt: 6.5, mb: -1.5 }} variant="h4SBold">
          {label}
        </Typography>
        <StyledGroup sx={{ px: 3, display: "flex", flexDirection: "column" }}>
          {items.map(renderNotification)}
        </StyledGroup>
      </>
    );
  };

  return (
    <Box
      sx={{
        mx: "auto",
        flex: "auto",
        display: "flex",
        flexDirection: "column",
        maxWidth: 1290,
        width: "100%",
        pt: 1.5,
      }}
    >
      <TablePlaceHolder isLoading={props.isLoading} rows={8} sx={{ mt: 9 }}>
        <Typography variant="h2SSemiBold" sx={{ mt: 4 }}>
          {props.label}
        </Typography>
        {props.items.length > 0 ? (
          <>
            {renderItems(todayItems, "New")}
            {renderItems(yesterdayItems, "Yesterday")}
            {renderItems(previousItems, "Previous")}
          </>
        ) : (
          <Box
            sx={{
              flex: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography variant="h1SSemiBold" sx={{ color: "gray3" }}>
              You have no notifications
            </Typography>
            {props.showConfetti && (
              <Confetti
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
              />
            )}
          </Box>
        )}
      </TablePlaceHolder>
    </Box>
  );
};

const NewNotifications = () => {
  const data = useNotifications(false);
  const prevLength = useRef<number>(0);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    if (prevLength.current > 0 && data.items.length === 0) {
      setShowConfetti(true);
    }

    prevLength.current = data.items.length;
  }, [data.items.length]);

  return (
    <NotificationList
      label="New"
      isLoading={data.isLoading}
      items={data.items}
      onClearClick={data.handleClearNotification}
      onSnooze={data.handleSnoozeNotification}
      showConfetti={showConfetti}
    />
  );
};

const ClearedNotifications = () => {
  const data = useNotifications(true);
  return (
    <NotificationList
      label="Cleared"
      isLoading={data.isLoading}
      items={data.items}
      onClearClick={data.handleRestoreNotification}
      onSnooze={data.handleSnoozeNotification}
    />
  );
};

export const NotificationsPage = () => {
  return (
    <RootPage sx={{ backgroundColor: "gray1", paddingBottom: 0 }}>
      <RootPageHeader
        title="Notifications"
        isSmall
        items={[
          {
            label: "New",
            url: notificationsPage.new,
          },
          {
            label: "Cleared",
            url: notificationsPage.cleared,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <AccessRouteControl
          items={[
            {
              path: notificationsPage.new,
              element: <NewNotifications />,
            },
            {
              path: notificationsPage.cleared,
              element: <ClearedNotifications />,
            },
          ]}
          defaultPath={notificationsPage.new}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
