import { useTheme } from "@mui/material";
import { useSimulationSimulationJobGetExtendedStatisticsRetrieveQuery } from "api/carApi.generated";
import { useReturnScenarios } from "app/useReturnScenarios";

import { isDefined } from "utils";
import {
  CarPortfolioChartItem,
  CarPortfolioHeatmapChartDataset,
  CarPortfolioHeatmapChartItem,
  CarPortfolioStatPeriod,
} from "../assetAllocation/asset-allocation-types";
import {
  AssetClassLoadType,
  useAssetClasses,
} from "features/assets/useAssetClasses";
import { getAssetClassIcon } from "components/AssetClassSelector";
import { useMemo } from "react";

export const useAssetClassExtendedStat = (params: {
  type: AssetClassLoadType;
  simulationJobId?: string;
  filterByUsableInPortfolios?: boolean;
}) => {
  const assetClasses = useAssetClasses({
    type: params.type,
    simulationJobId: params.simulationJobId,
    filterByUsableInPortfolios: params.filterByUsableInPortfolios,
  });

  const extendedStat =
    useSimulationSimulationJobGetExtendedStatisticsRetrieveQuery(
      {
        id: assetClasses.simulationJobId ?? "",
      },
      {
        skip: !assetClasses.simulationJobId,
      },
    );

  const returnScenarios = useReturnScenarios();

  const theme = useTheme();

  return useMemo(() => {
    const periods =
      returnScenarios.items
        .filter((rs) => rs.show_in_graphs)
        .map<CarPortfolioStatPeriod>((rs) => ({
          label: rs.name ?? "",
          startDate: rs.start_date ?? "",
          endDate: rs.end_date ?? "",
        })) ?? [];

    interface ExtStatItemResult {
      type: string;
      index: any[];
      values: number[];
    }

    interface ACStatItem {
      ac4_id: string;
      results: ExtStatItemResult[];
    }

    const getChartItems = (type: string, tooltipType?: string) => {
      return assetClasses.items
        .map<CarPortfolioChartItem | undefined>((ac) => {
          const acStatItem = (
            extendedStat.data?.extended_statistics as unknown as ACStatItem[]
          )?.find((i) => i.ac4_id === ac.level4Id);

          const statItem = acStatItem?.results.find((i) => i.type === type);

          const tooltipStatItem = tooltipType
            ? acStatItem?.results.find((i) => i.type === tooltipType)
            : undefined;

          return statItem
            ? {
                id: ac.level4Id,
                label: ac.name,
                fullLabel: `AC: ${ac.name}`,
                isDisabled: ac.isDisabled,
                icon: getAssetClassIcon(ac),
                isSelected: false,
                x: statItem.index,
                y: statItem.values,
                tooltipValues: tooltipStatItem?.values,
              }
            : undefined;
        })
        .filter(isDefined);
    };

    const oneYearUnderPerf = getChartItems("oneyr_hist_rolling_returns");
    const threeYearUnderPerf = getChartItems(
      "threeyr_hist_rolling_returns_annual",
      "threeyr_hist_rolling_returns",
    );
    const cumRelPerf = getChartItems("annual_fv");
    const multiPeriodReturnComparison = getChartItems(
      "month_hist_rolling_returns",
    );

    const getHeatmapChartItems = (isReal: boolean) => {
      const keyPrefix = isReal ? "real" : "nominal";
      interface Type {
        label: string;
        color: string;
        type: string;
        fill?: string;
      }

      const types: Type[] = [
        {
          label: "95th - 75th",
          color: theme.palette.gray2,
          type: `${keyPrefix}_heatmap_95`,
          fill: "+1",
        },
        {
          label: "75th - 60th",
          color: theme.palette.gray4,
          type: `${keyPrefix}_heatmap_75`,
          fill: "+1",
        },
        {
          label: "60th - 50th",
          color: theme.palette.gray6,
          type: `${keyPrefix}_heatmap_60`,
          fill: "+1",
        },
        {
          label: "50th",
          color: theme.palette.softBlack,
          type: `${keyPrefix}_heatmap_50`,
        },
        {
          label: "50th - 40th",
          color: theme.palette.gray6,
          type: `${keyPrefix}_heatmap_40`,
          fill: "-1",
        },
        {
          label: "40th - 25th",
          color: theme.palette.gray4,
          type: `${keyPrefix}_heatmap_25`,
          fill: "-1",
        },
        {
          label: "25th - 5th",
          color: theme.palette.gray2,
          type: `${keyPrefix}_heatmap_5`,
          fill: "-1",
        },
      ];

      return assetClasses.items
        .map<CarPortfolioHeatmapChartItem>((ac) => ({
          id: ac.firmAssetClassId,
          label: ac.name,
          fullLabel: `AC: ${ac.name}`,
          isSelected: false,
          datasets: types
            .map<CarPortfolioHeatmapChartDataset | undefined>(
              ({ type, ...item }) => {
                const statItemResult = (
                  extendedStat.data
                    ?.extended_statistics as unknown as ACStatItem[]
                )
                  ?.find((i) => i.ac4_id === ac.level4Id)
                  ?.results.find((i) => i.type === type);

                return statItemResult
                  ? {
                      ...item,
                      x: statItemResult.index,
                      y: statItemResult.values.map((i) => i * 100),
                    }
                  : undefined;
              },
            )
            .filter(isDefined),
        }))
        .filter(isDefined)
        .filter((i) => i.datasets.length);
    };

    const realHeatmap = getHeatmapChartItems(true);
    const nominalHeatmap = getHeatmapChartItems(false);

    const isLoading =
      assetClasses.isLoading ||
      extendedStat.isLoading ||
      returnScenarios.isLoading;

    return {
      isLoading,
      assetClasses,
      periods,

      oneYearUnderPerf,
      threeYearUnderPerf,
      cumRelPerf,
      multiPeriodReturnComparison,

      realHeatmap,
      nominalHeatmap,
    };
  }, [assetClasses, extendedStat, returnScenarios, theme]);
};
