import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { SettingsUserSettings } from "./SettingsUserSettings";
import { settingsPages } from "route.const";
import { SettingsNotificationSettings } from "./SettingsNotificationSettings";
import { SettingsFirmSettings } from "./SettingsFirmSettings";
import { SettingsDisclosures } from "./SettingsDisclosures";
import { useUser } from "./useUser";
import { SettingsAssetClassSettings } from "./SettingsAssetClassSettings";
import { SettingsBackEnd } from "./SettingsBackEnd";
import { CarModuleSectionCode } from "app/useUserAccess";
import { AccessRouteControl } from "components/AccessRouteControl";

export const SettingsPage = () => {
  const user = useUser();

  return (
    <RootPage sx={{ paddingBottom: 0 }}>
      <RootPageHeader
        title="Settings"
        isSmall
        items={[
          {
            label: "User Settings",
            url: settingsPages.userSettings,
            moduleSection: CarModuleSectionCode.SETTINGS_USER_PROFILE,
          },
          {
            label: "Firm Settings",
            url: settingsPages.firmSettings,
            // hidden: !isAdmin,
            moduleSection: CarModuleSectionCode.SETTINGS_MANAGE_FIRM,
          },
          {
            label: "Notifications",
            url: settingsPages.notifications,
            moduleSection: CarModuleSectionCode.SETTINGS_NOTIFICATIONS,
          },
          {
            label: "Asset Class Settings",
            url: settingsPages.assetClassSettings,
            moduleSection:
              CarModuleSectionCode.SETTINGS_ASSET_CLASS_IMPLEMENTATION,
          },
          {
            label: "Disclosures",
            url: settingsPages.disclosuresUrl,
            moduleSection: CarModuleSectionCode.SETTINGS_DISCLOSURES,
          },
          {
            label: "Back End",
            url: settingsPages.backEndUrl,
            hidden: !user.isCaravelUser,
            // moduleSection: CarModuleSectionCode.SETTINGS_
          },
        ]}
      />
      <RootPageTransparentPaper>
        <AccessRouteControl
          items={[
            {
              path: settingsPages.userSettings,
              element: <SettingsUserSettings />,
              moduleSection: CarModuleSectionCode.SETTINGS_USER_PROFILE,
            },
            {
              path: settingsPages.firmSettings,
              element: <SettingsFirmSettings />,
              // hidden: !isAdmin,
              moduleSection: CarModuleSectionCode.SETTINGS_MANAGE_FIRM,
            },
            {
              path: settingsPages.notifications,
              element: <SettingsNotificationSettings />,
              moduleSection: CarModuleSectionCode.SETTINGS_NOTIFICATIONS,
            },
            {
              path: settingsPages.assetClassSettings,
              element: <SettingsAssetClassSettings />,
              moduleSection:
                CarModuleSectionCode.SETTINGS_ASSET_CLASS_IMPLEMENTATION,
            },
            {
              path: settingsPages.disclosuresPath,
              element: <SettingsDisclosures />,
              moduleSection: CarModuleSectionCode.SETTINGS_DISCLOSURES,
            },
            {
              path: settingsPages.backEndPath,
              element: <SettingsBackEnd />,
              hidden: !user.isCaravelUser,
              // moduleSection: CarModuleSectionCode.SETTINGS_
            },
          ]}
          defaultPath={settingsPages.userSettings}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
