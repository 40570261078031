import { Box, BoxProps, Typography, styled, useTheme } from "@mui/material";
import { Chart } from "react-chartjs-2";
import { formatDate, formatDisplayTypeValue, isDefined } from "utils";
import { memo, useCallback, useRef } from "react";
import {
  ChartTooltip,
  defaultChartTooltipData,
  RenderTooltip,
  useChartTooltip,
} from "components/ChartTooltip";
import { ChartPlugins, ChartUtils } from "chartUtils";
import { useChartHighlight } from "app/useChartHighlight";
import { ChartJSOrUndefined } from "react-chartjs-2/dist/types";
import { ChartDataset } from "chart.js";
import { caravelColors, chartsColor } from "theme";
import { AssetClassRegressionResult } from "./useSimulationModels";

const chartHeight = 490;

type DS = ChartDataset<
  "line" | "scatter",
  {
    x: number;
    y: number;
  }[]
> & {
  color: string;
};

export const getSimDataTableChartData = (data?: AssetClassRegressionResult) => {
  console.log({ data });

  const scatterData =
    data?.x_data.map((x, idx) => ({
      x,
      y: data?.y_data.at(idx) ?? 0,
      date: formatDate(data?.data_dates?.at(idx)),
    })) ?? [];

  const scatterRadius = scatterData.length > 100 ? 1 : 5;
  const datasets: DS[] = [
    {
      id: "scatter_last",
      label: "scatter_last",
      type: "scatter",
      data: scatterData.slice(-1),
      color: chartsColor.red,
      pointBackgroundColor: chartsColor.red,
      pointBorderColor: chartsColor.red,
      pointRadius: scatterRadius,
    },
    {
      id: "scatter",
      label: "scatter",
      type: "scatter",
      data: scatterData.slice(0, -1),
      color: chartsColor.blue,
      pointBackgroundColor: chartsColor.blue,
      pointBorderColor: chartsColor.blue,
      pointRadius: scatterRadius,
    },
    {
      id: "line",
      label: "line",
      type: "line",
      data: scatterData.map((p) => ({
        ...p,
        y: p.x * (data?.beta ?? 0) + (data?.intercept ?? 0),
      })),
      color: caravelColors.softBlack,
      borderColor: caravelColors.softBlack,
      borderWidth: 3,
      pointRadius: 0,
    },
  ];

  return {
    datasets,
    xLabel: data?.x_label.at(0) ?? "",
    yLabel: data?.y_label.at(0) ?? "",
  };
};

const StyledChart = styled(Chart)(({ theme }) => ({
  width: "100%",
  height: chartHeight,
  maxHeight: chartHeight,
})) as typeof Chart;

type ChartRef = ChartJSOrUndefined<
  "line" | "scatter",
  {
    x: number;
    y: number;
  }[],
  unknown
>;

interface SimulationModelsChartProps {
  sx?: BoxProps["sx"];
  data?: AssetClassRegressionResult;
  isWhiteContext?: boolean;
  isFixedIncome?: boolean;
}

export const SimulationModelsChart = memo(
  (props: SimulationModelsChartProps) => {
    const chartHighlight = useChartHighlight();

    const { datasets, xLabel, yLabel } = getSimDataTableChartData(props.data);

    const { tooltipPlugin, tooltipData, setTooltipData } = useChartTooltip({
      chartHighlight,
    });
    const chartRef = useRef<ChartRef | null>();

    const chartRefCallback = useCallback(
      (value: ChartRef | null) => {
        chartHighlight.unsubscribe(chartRef.current);
        chartRef.current = value;

        chartHighlight.subscribe(chartRef.current, (hlValue) => {
          if (
            !isDefined(hlValue.datasetIndex) ||
            !isDefined(hlValue.dataIndex)
          ) {
            setTooltipData(defaultChartTooltipData);
            return;
          }

          if (chartRef.current) {
            const { width, height } = chartRef.current.canvas;
            const data = chartRef.current.data.datasets.at(hlValue.datasetIndex)
              ?.data;

            const value = data?.at(hlValue.dataIndex);

            if (value) {
              const x = chartRef.current.scales.x.getPixelForValue(value.x);
              const y = chartRef.current.scales.y.getPixelForValue(value.y);
              setTooltipData({
                opacity: 1,
                x,
                y,
                canvasSize: { width, height },
                datasetIndex: hlValue.datasetIndex,
                dataIndex: hlValue.dataIndex,
              });
            }
          }

          chartRef.current?.update();
        });
      },
      [chartHighlight, setTooltipData],
    ) as any;

    const theme = useTheme();

    const xFormatter = formatDisplayTypeValue("FLOAT", {
      decimalPlaces: 2,
      forceShowDecimals: true,
    });

    const yFormatter = formatDisplayTypeValue("FLOAT", {
      decimalPlaces: 2,
      forceShowDecimals: true,
    });

    const xFormatterTooltip = formatDisplayTypeValue("FLOAT", {
      decimalPlaces: props.isFixedIncome ? 4 : 2,
      forceShowDecimals: true,
    });

    const yFormatterTooltip = formatDisplayTypeValue("FLOAT", {
      decimalPlaces: props.isFixedIncome ? 4 : 2,
      forceShowDecimals: true,
    });

    const formatXValue = (value: number | string) =>
      typeof value === "number" ? xFormatter(value) : "";

    const formatYValue = (value: number | string) =>
      typeof value === "number" ? yFormatter(value) : "";

    const renderTooltip: RenderTooltip = (datasetIndex, dataIndex) => {
      const ds = datasets[datasetIndex];
      if (!ds) {
        return <></>;
      }

      const dataPoint = ds.data?.[dataIndex] as unknown as
        | {
            x: number;
            y: number;
            date: string;
          }
        | undefined;

      const xValue = dataPoint?.x ?? 0;
      const yValue = dataPoint?.y ?? 0;
      const date = dataPoint?.date ?? "";
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {date && (
            <Typography variant="par01SemiBold" sx={{ mb: 1 }}>
              {date}
            </Typography>
          )}
          <Typography variant="par01Regular">
            {`${xLabel}: ${xFormatterTooltip(xValue)}`}
          </Typography>
          <Typography variant="par01Regular">
            {`${yLabel}: ${yFormatterTooltip(yValue)}`}
          </Typography>
        </Box>
      );
    };

    return (
      <ChartTooltip
        tooltipData={tooltipData}
        renderTooltip={renderTooltip}
        sx={props.sx}
      >
        <StyledChart
          ref={chartRefCallback}
          datasetIdKey="id"
          type="line"
          data={{
            datasets,
          }}
          options={{
            // animation: true,
            maintainAspectRatio: false,
            scales: {
              x: {
                type: "linear",
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "400",
                  },
                  callback: formatXValue,
                },
                border: { display: false },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                },
                title: {
                  display: true,
                  text: xLabel,
                  color: theme.palette.softBlack,
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                    lineHeight: 1,
                  },
                  padding: {
                    top: 10,
                  },
                },
              },
              y: {
                type: "linear",
                ticks: {
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "400",
                  },
                  callback: formatYValue,
                },
                border: { display: false },
                grid: {
                  color: ChartUtils.gridColor,
                  lineWidth: 2,
                  tickColor: theme.palette.white,
                },
                title: {
                  display: true,
                  text: yLabel,
                  color: theme.palette.softBlack,
                  font: {
                    family: theme.typography.fontFamily,
                    size: 15,
                    weight: "600",
                    lineHeight: 1,
                  },
                  padding: {
                    bottom: 20,
                  },
                },
              },
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: tooltipPlugin,
              roundedBackground: {
                contextColor: props.isWhiteContext
                  ? theme.palette.white
                  : theme.palette.gray1,
                backgroundColor: props.isWhiteContext
                  ? theme.palette.gray1
                  : theme.palette.white,
                borderColor: theme.palette.gray7,
                borderRadius: 5,
              },
            },
          }}
          plugins={[ChartPlugins.roundedBackground]}
        />
      </ChartTooltip>
    );
  },
);
