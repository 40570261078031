import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { useNavigate } from "react-router-dom";
import { rootPages, simulationPages } from "route.const";
import { SimulationSingle } from "./SimulationSingle";
import { useEffect } from "react";
import { useUser } from "pages/settings/useUser";
import { SimulationABTest } from "./SimulationABTest";
import { SimulationModels } from "./SimulationModels";
import { SimulationRun } from "./SimulationRun";
import { CarModuleSectionCode } from "app/useUserAccess";
import { AccessRouteControl } from "components/AccessRouteControl";

export const SimulationPage = () => {
  const { isLoading, isCaravelUser } = useUser();
  const navigate = useNavigate();
  useEffect(() => {
    if (!isLoading && !isCaravelUser) {
      navigate(rootPages.home);
    }
  }, [isCaravelUser, isLoading, navigate]);

  return (
    <RootPage sx={{ backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Simulation Reports"
        items={[
          {
            label: "Price - Driven",
            url: simulationPages.priceDrivenUrl,
            moduleSection:
              CarModuleSectionCode.SIMULATION_ANALYSIS_PRICE_DRIVEN,
          },
          {
            label: "Static",
            url: simulationPages.staticUrl,
            moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_STATIC,
          },
          {
            label: "Models",
            url: simulationPages.modelsUrl,
            moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_MODELS,
          },
          {
            label: "A/B Test",
            url: simulationPages.abTestUrl,
            moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_AB,
          },
          {
            label: "Run",
            url: simulationPages.runUrl,
            moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_RUN,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <AccessRouteControl
          items={[
            {
              path: simulationPages.priceDrivenPath,
              element: <SimulationSingle isStatic={false} />,
              moduleSection:
                CarModuleSectionCode.SIMULATION_ANALYSIS_PRICE_DRIVEN,
            },
            {
              path: simulationPages.staticPath,
              element: <SimulationSingle isStatic={true} />,
              moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_STATIC,
            },
            {
              path: simulationPages.modelsPath,
              element: <SimulationModels />,
              moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_MODELS,
            },
            {
              path: simulationPages.abTestPath,
              element: <SimulationABTest />,
              moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_AB,
            },
            {
              path: simulationPages.runPath,
              element: <SimulationRun />,
              moduleSection: CarModuleSectionCode.SIMULATION_ANALYSIS_RUN,
            },
          ]}
          defaultPath={simulationPages.priceDrivenUrl}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
