import {
  useCommonRolesListQuery,
  useCommonRoleTypesListQuery,
} from "api/carApi.generated";
import { useMemo } from "react";
import { sortBySortOrder } from "utils";

export const useRoles = () => {
  const roles = useCommonRolesListQuery();
  const roleTypes = useCommonRoleTypesListQuery();

  return useMemo(() => {
    const items = sortBySortOrder(roles.data);

    const getRole = (roleId?: string | null) => {
      return items.find((i) => i.id === roleId);
    };
    const getRoleType = (roleId?: string | null) => {
      const role = getRole(roleId);

      // todo should this be moved to getRole ???
      if (!role?.is_active) {
        return undefined;
      }

      const roleType = roleTypes.data?.find((i) => i.id === role.role_type);

      if (roleType?.is_active) {
        return roleType;
      }
    };

    const isAdminRole = (roleId?: string | null) =>
      !!getRoleType(roleId)?.is_admin;

    return {
      isLoading: roles.isLoading || roleTypes.isLoading,
      items,

      getRole,
      isAdminRole,

      canAccessBilling: (roleId?: string | null) => isAdminRole(roleId), // todo clarify
    };
  }, [roles, roleTypes]);
};
