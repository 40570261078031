import {
  Badge,
  Box,
  ClickAwayListener,
  Divider,
  styled,
  Typography,
} from "@mui/material";
import { ReactComponentLike } from "prop-types";

import { useSideBar } from "./useSideBar";
import { NavLink } from "react-router-dom";
import { BoxProps } from "@mui/system";
import SimpleBar from "simplebar-react";
import { Fragment } from "react";
import { LogoutIcon } from "./sideBarIcons";
import { TablePlaceHolder } from "components/PlaceHolder";

const StyledLink = styled(NavLink)({
  textDecoration: "none",
});

interface SideBarButtonProps {
  icon: ReactComponentLike;
  label: string;
  url: string;
  end?: boolean;
  sx?: BoxProps["sx"];
  isSmall?: boolean;
  onClick?: () => void;
  afterClick?: () => void;
  hasBadge?: boolean;
  badgeCount?: number;
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  ".MuiBadge-badge": {
    height: 13,
    fontSize: "9px",
    minWidth: 13,
    paddingLeft: "2px",
    paddingRight: "2px",
  },
}));

const SideBarButton = (props: SideBarButtonProps) => {
  return (
    <StyledLink
      onClick={(e) => {
        if (props.onClick) {
          props.onClick();
          e.preventDefault();
        }
        props.afterClick?.();
      }}
      to={props.url}
      end={props.end}
      sx={props.sx}
    >
      <Box
        sx={{
          pt: 1.8,
          pb: props.isSmall ? 1.25 : 1.8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 1,
          color: "softBlack",
          ".active & > *": {
            color: "caravelOrangePrimary",
          },
        }}
      >
        {props.hasBadge ? (
          <StyledBadge color="secondary" badgeContent={props.badgeCount}>
            <props.icon />
          </StyledBadge>
        ) : (
          <props.icon />
        )}
        <Typography
          color="textPrimary"
          variant="par01Regular"
          sx={{
            textAlign: "center",
            whiteSpace: "pre",
            fontWeight: 400,
            fontSize: props.isSmall ? "9px" : "11px",
            lineHeight: props.isSmall ? "10px" : "12px",
          }}
        >
          {props.label}
        </Typography>
      </Box>
    </StyledLink>
  );
};

interface SideBarProps {
  afterClick?: () => void;
}

const StyledRoot = styled(Box)(({ theme }) => ({
  zIndex: 100,
  height: "100%",
  display: "flex",
  flexDirection: "column",
  boxShadow: "4px 4px 10px 0px #0000000D",
  overflow: "hidden",
  ".content": {
    width: 78,
    height: "100%",
    border: `solid 1px ${theme.palette.gray4}`,
    backgroundColor: theme.palette.white,
  },
  ".simplebar-content": {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",

    ".MuiDivider-root": {
      backgroundColor: theme.palette.gray4,
      height: "1px",
      width: "53px",
    },
  },
}));

export const SideBar = (props: SideBarProps) => {
  const sideBar = useSideBar();

  const handleAfterClick = () => {
    props.afterClick?.();
  };

  return (
    <ClickAwayListener onClickAway={handleAfterClick}>
      <StyledRoot>
        <SimpleBar className="content">
          <Box sx={{ mt: 1 }} />
          <TablePlaceHolder
            isLoading={sideBar.isLoading}
            rows={6}
            sx={{ p: 1.5 }}
            childSx={{ width: 52, height: 52, borderRadius: "50%" }}
          >
            {sideBar.items.map((i, idx, arr) => (
              <Fragment key={i.url}>
                <SideBarButton
                  icon={i.icon}
                  label={i.label}
                  url={i.url}
                  end={i.end}
                  afterClick={handleAfterClick}
                />
                {idx < arr.length - 1 && <Divider />}
              </Fragment>
            ))}
            <SideBarButton
              sx={{
                mt: "auto",
              }}
              icon={LogoutIcon}
              label={"Logout"}
              url={"logout"}
              onClick={sideBar.logout}
              afterClick={handleAfterClick}
            />
          </TablePlaceHolder>
        </SimpleBar>
      </StyledRoot>
    </ClickAwayListener>
  );
};
