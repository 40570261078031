import { RootPage, RootPageTransparentPaper } from "components/RootPage";
import { RootPageHeader } from "components/RootPageHeader";
import { assetClassAnalysisAnalyzePages } from "route.const";
import { AssetClassAnalysisSummary } from "./AssetClassAnalysisSummary";
import { AssetClassAnalysisPerformanceRisk } from "./AssetClassAnalysisPerformanceRisk";
import { AssetClassAnalysisSimulatedForwardProbabilities } from "./AssetClassAnalysisSimulatedForwardProbabilities";
import { AssetClassAnalysisDistanceFromTrend } from "./AssetClassAnalysisDistanceFromTrend";
import { AssetClassAnalysisSectorAnalysis } from "./AssetClassAnalysisSectorAnalysis";
import { AssetClassAnalysisMultiPeriodReturnComparison } from "./AssetClassAnalysisMultiPeriodReturnComparison";
import { SourceDataAsOf } from "pages/assetAllocation/SourceDataAsOf";
import { CarModuleSectionCode } from "app/useUserAccess";
import { AccessRouteControl } from "components/AccessRouteControl";

export const AssetClassAnalysisAnalyze = () => {
  return (
    <RootPage sx={{ backgroundColor: "white" }}>
      <RootPageHeader
        isSmall
        title="Asset Class Analysis - Analyze"
        items={[
          {
            label: "Summary",
            url: assetClassAnalysisAnalyzePages.summary,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY,
          },
          {
            label: "Performance Risk",
            url: assetClassAnalysisAnalyzePages.performanceRisk,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK,
          },
          {
            label: "Multi-Period Return",
            url: assetClassAnalysisAnalyzePages.multiPeriodReturnComparison,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN,
          },
          {
            label: "Simulated Forward Probabilities",
            url: assetClassAnalysisAnalyzePages.simulatedForwardProbabilities,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY,
          },
          {
            label: "Distance From Trend",
            url: assetClassAnalysisAnalyzePages.distanceFromTrend,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND,
          },
          {
            label: "Sector Analysis",
            url: assetClassAnalysisAnalyzePages.sectorAnalysis,
            moduleSection:
              CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS,
          },
        ]}
      />
      <RootPageTransparentPaper>
        <SourceDataAsOf />
        <AccessRouteControl
          items={[
            {
              path: assetClassAnalysisAnalyzePages.summary,
              element: <AssetClassAnalysisSummary />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SUMMARY,
            },
            {
              path: assetClassAnalysisAnalyzePages.performanceRisk,
              element: <AssetClassAnalysisPerformanceRisk />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_PERFORMANCE_RISK,
            },
            {
              path: assetClassAnalysisAnalyzePages.multiPeriodReturnComparison,
              element: <AssetClassAnalysisMultiPeriodReturnComparison />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_MULTI_PERIOD_RETURN,
            },
            {
              path: assetClassAnalysisAnalyzePages.simulatedForwardProbabilities,
              element: <AssetClassAnalysisSimulatedForwardProbabilities />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SIMULATED_FORWARD_PROBABILITY,
            },
            {
              path: assetClassAnalysisAnalyzePages.distanceFromTrend,
              element: <AssetClassAnalysisDistanceFromTrend />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_DISTANCE_FROM_TREND,
            },
            {
              path: assetClassAnalysisAnalyzePages.sectorAnalysis,
              element: <AssetClassAnalysisSectorAnalysis />,
              moduleSection:
                CarModuleSectionCode.ASSET_CLASS_MONTHLY_REPORT_SECTOR_ANALYSIS,
            },
          ]}
          defaultPath={assetClassAnalysisAnalyzePages.summary}
        />
      </RootPageTransparentPaper>
    </RootPage>
  );
};
