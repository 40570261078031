import { Box, BoxProps, Typography } from "@mui/material";
import { PropsWithChildren } from "react";
import { CarTooltipBox } from "components/TooltipBox";

interface CarAssetAllocationSectionBoxProps {
  label: string;
  sx?: BoxProps["sx"];
  pendoClassName?: string;
}

export const CarAssetAllocationSectionBox = (
  props: PropsWithChildren<CarAssetAllocationSectionBoxProps>,
) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2, ...props.sx }}>
      <Box sx={{ display: "flex" }}>
        <Typography variant="h5SSemiBold">{props.label}</Typography>
        {props.pendoClassName && (
          <CarTooltipBox
            sx={{ mt: 0.3, ml: 0.5 }}
            className={props.pendoClassName}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          bgcolor: "gray1",
          border: "1px solid",
          borderColor: "gray3",
          px: 4.5,
          py: 6,
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};
