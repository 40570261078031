import _ from "lodash";
import {
  dateExtraItems,
  frequencyItems,
  isDebug,
  priorityItems,
} from "./const";
import { CarDateExtra, CarPriorityKind, CarSavingsExtra } from "./types";
import { parseISO, format } from "date-fns";
import { DisplayTypeEnum2 } from "api/carApi.generated";

export const todoValue = Number.MIN_SAFE_INTEGER + 10;

export const roundTo = (value: number, decimalPlaces?: number) => {
  const factor = Math.pow(10, decimalPlaces ?? 0);
  const result = factor
    ? Math.round(value * factor) / factor
    : Math.round(value);
  return result === 0 ? 0 : result; // get rid of: -0
};

export const formatCurrency = (value: number, decimalPlaces?: number) =>
  value === todoValue
    ? "todo"
    : new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        maximumFractionDigits: decimalPlaces ?? 0,
      }).format(roundTo(value, decimalPlaces));

export interface DecimalOptions {
  decimalPlaces: number;
  forceShowDecimals?: boolean;
}

export const formatPercentFactor = (
  value: number,
  decimalOptions?: number | DecimalOptions,
) => {
  const options: DecimalOptions =
    typeof decimalOptions === "object"
      ? decimalOptions
      : { decimalPlaces: decimalOptions ?? 0 };

  return value === todoValue
    ? "todo"
    : new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: options.decimalPlaces,
        minimumFractionDigits: options.forceShowDecimals
          ? options.decimalPlaces
          : undefined,
      }).format(roundTo(value, options.decimalPlaces + 2));
};

export const formatPercent = (value: number, decimalPlaces?: number) =>
  value === todoValue
    ? "todo"
    : new Intl.NumberFormat("en-US", {
        style: "percent",
        maximumFractionDigits: decimalPlaces ?? 0,
      }).format(roundTo(value, decimalPlaces) / 100);

export const formatNumber = (
  value: number,
  decimalOptions?: number | DecimalOptions,
) => {
  const options: DecimalOptions =
    typeof decimalOptions === "object"
      ? decimalOptions
      : { decimalPlaces: decimalOptions ?? 0 };

  return value === todoValue
    ? "todo"
    : new Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: options.decimalPlaces,
        minimumFractionDigits: options.forceShowDecimals
          ? options.decimalPlaces
          : undefined,
      }).format(roundTo(value, options.decimalPlaces + 2));
};

export const formatDisplayTypeValue =
  (displayType: DisplayTypeEnum2, decimalOptions?: DecimalOptions) =>
  (value?: number) => {
    if (!isDefined(value)) {
      return "-";
    }

    switch (displayType) {
      case "PERCENTAGE":
        return formatPercentFactor(value, decimalOptions ?? 1);
      case "FLOAT":
        return formatNumber(value, decimalOptions ?? 1);
      case "INTEGER":
        return formatNumber(value, decimalOptions ?? 0);
    }
  };

export function isDefined<TValue>(
  value: TValue | null | undefined,
): value is TValue {
  return value !== null && value !== undefined;
}

export function checkDefined<TValue>(value: TValue | null | undefined): TValue {
  if (value === null || value === undefined) {
    throw new Error(`checkDefined value is ${String(value)}`);
  }
  return value;
}

export function deleteEmpty<T extends object, K extends keyof T>(
  obj: T,
  key: K,
): void {
  const value = obj[key];
  if (typeof value === "string" && value.trim().length === 0) {
    delete obj[key];
  }
}

export function deleteZero<T extends object, K extends keyof T>(
  obj: T,
  key: K,
): void {
  const value = obj[key];
  if (typeof value === "number" && value === 0) {
    delete obj[key];
  }
}

export function setZeroIfUndefined<K extends string | number>(
  obj: { [P in K]?: number },
  key: K,
): void {
  if (obj[key] === undefined) {
    obj[key] = 0;
  }
}

export function difference(object: any, base: any) {
  function changes(object: any, base: any) {
    return _.transform(object, function (result: any, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

export const roundCurrency = (value: number) => Math.round(value * 100) / 100;

export const debugLog = isDebug ? console.log : (...data: any[]) => {};
export const debugTable = isDebug ? console.table : (...data: any[]) => {};

export function bindField<T extends object, K extends keyof T>(
  props: { item: T; onChange: (value: T) => void },
  key: K,
) {
  return {
    value: props.item[key],
    onChange: (value: T[K]) => props.onChange({ ...props.item, [key]: value }),
  };
}

export const carPriorityKindToColor = (kind?: CarPriorityKind) => {
  switch (kind) {
    case "NEED":
      return "needs";
    case "DREAM":
      return "dreams";
    case "WANT":
      return "wants";
    default:
      return "needs";
  }
};

export const dateExtraToString = (value?: CarDateExtra) => {
  if (!value) {
    return "";
  }
  const label = dateExtraItems.find((i) => i.value === value.kind)?.label ?? "";
  if (value.kind === "AGE") {
    return `${label}: ${value.age}`;
  }
  if (value.kind === "YEAR") {
    return `${label}: ${value.year}`;
  }
  return label;
};

export const isDateExtraSame = (
  value1?: CarDateExtra,
  value2?: CarDateExtra,
) => {
  if (!value1 && !value2) {
    return true;
  }

  if (!value1 || !value2) {
    return false;
  }

  if (value1.kind !== value2.kind) {
    return false;
  }

  switch (value1.kind) {
    case "AGE":
      return (
        value1.age === value2.age &&
        value1.age_relationship === value2.age_relationship
      );

    case "YEAR":
      return value1.year === value2.year;

    default:
      return true;
  }
};

export const isDateExtraValid = (value?: CarDateExtra) => {
  if (!value) {
    return true;
  }

  switch (value.kind) {
    case "AGE":
      return !!value.age && !!value.age_relationship;

    case "YEAR":
      return !!value.year;

    case "PRIMARY_RETIREMENT":
    case "SECONDARY_RETIREMENT":
    case "PRIMARY_DEATH":
    case "SECONDARY_DEATH":
    case "SECOND_DEATH":
      return true;

    default:
      return false;
  }
};

export const savingsExtraToString = (value?: CarSavingsExtra) => {
  if (!value) {
    return "";
  }

  if (isDefined(value.amount_percent)) {
    return formatPercent(value.amount_percent);
  }

  if (isDefined(value.amount_dollar)) {
    return formatCurrency(value.amount_dollar);
  }

  if (value.max_contribution !== undefined) {
    return value.max_contribution ? "Max: Yes" : "Max: No";
  }
};

export const priorityToString = (value?: CarPriorityKind) =>
  priorityItems.find((i) => i.value === value)?.label ?? "";

export const priorityNumToString = (value?: number) =>
  priorityItems.find((i) => i.numValue === value)?.label ?? "";

export const formatDate = (date?: string) => {
  if (date) {
    return format(parseISO(date), "P");
  } else {
    return "";
  }
};

export const formatDateTime = (date?: string) => {
  if (date) {
    return format(parseISO(date), "Pp");
  } else {
    return "";
  }
};

export const formatYear = (date?: string) => {
  if (date) {
    return format(parseISO(date), "yyyy");
  } else {
    return "";
  }
};

export const frequencyToString = (value?: number) =>
  frequencyItems.find((i) => i.value === value)?.label ?? "";

export const isEvenOdd = (index: number) => index % 2 !== 0;
export const isOddEven = (index: number) => index % 2 === 0;

const EPSILON_RATE = 1 + Number.EPSILON;
const EPSILON_ZERO = Number.MIN_VALUE;

export function epsilonEquals(a: number | undefined, b: number | undefined) {
  if (!isDefined(a) || !isDefined(b)) {
    return false;
  }
  if (Number.isNaN(a) || Number.isNaN(b)) {
    return false;
  }
  if (a === 0 || b === 0) {
    return a <= b + EPSILON_ZERO && b <= a + EPSILON_ZERO;
  }
  return a <= b * EPSILON_RATE && b <= a * EPSILON_RATE;
}

export function* splitArrayIntoChunks<T>(
  arr: T[],
  chunkLength: number,
): Generator<T[], void> {
  for (let i = 0; i < arr.length; i += chunkLength) {
    yield arr.slice(i, i + chunkLength);
  }
}

export function* splitArrayIntoChunksConditionally<T>(
  arr: T[],
  condition: (prevChank: T[], currChank: T[]) => "prev" | "current" | "none",
): Generator<T[], void> {
  let prevChunk: T[] = [];
  let currItem: T[] = [];
  while (arr.length) {
    prevChunk = [...prevChunk, ...currItem];
    currItem = [arr.shift()!];
    switch (condition(prevChunk, [...prevChunk, ...currItem])) {
      case "prev":
        yield prevChunk;
        prevChunk = [];
        break;
      case "current":
        yield [...prevChunk, ...currItem];
        prevChunk = [];
        currItem = [];
        break;
    }
  }
  prevChunk = [...prevChunk, ...currItem];
  if (prevChunk.length && condition(prevChunk, []) === "prev") {
    yield prevChunk;
  }
}

export function splitByWord(text: string, maxCharsInLine: number) {
  return Array.from(
    splitArrayIntoChunksConditionally(text.split(" "), (prev, curr) => {
      if (
        !curr.length ||
        (curr.reduce((sum, s) => sum + s.length, 0) + curr.length - 1 >
          maxCharsInLine &&
          prev.length)
      )
        return "prev";
      return "none";
    }),
  ).map((chunk) => chunk.join(" "));
}

export const openInNewTab = (url: string) => window?.open(url, "_blank");

export function requiredLabel(label: string, required: boolean): string {
  return `${required ? "*" : ""}${label}`;
}

export function toTitleCase(str?: string) {
  return str
    ? str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      })
    : str;
}

export const setDisplayName = (obj: Record<string, any>) => {
  if (isDebug) {
    Object.entries(obj).forEach(([compName, component]) => {
      if (component) {
        (component as any).displayName = compName;
      }
    });
  }
};

export const sortBySortOrder = <T extends { sort_order?: number | null }>(
  items?: T[] | null,
) =>
  Array.from(items ?? []).sort(
    (a, b) => (a.sort_order ?? 0) - (b.sort_order ?? 0),
  );

export const getNextSortOrder = <T extends { sort_order?: number | null }>(
  items?: T[] | null,
) =>
  Array.from(items ?? []).reduce(
    (acc, i) => Math.max(acc, i.sort_order ?? 0),
    0,
  ) + 100;

export const getTimeoutPromise = (delay: number) =>
  new Promise((resolve) => setTimeout(resolve, delay));
