import { portfolioAnalysisPages } from "route.const";
import { PortfolioAnalysisDashboardPage } from "./portfolioAnalysisDashboard/PortfolioAnalysisDashboardPage";
import { EditPortfolioPage } from "./editPortfolio/EditPortfolioPage";
import { AccessRouteControl } from "components/AccessRouteControl";
import { CarModuleSectionCode } from "app/useUserAccess";

export const PortfolioAnalysisPage = () => {
  return (
    <AccessRouteControl
      items={[
        {
          path: portfolioAnalysisPages.dashboard,
          element: <PortfolioAnalysisDashboardPage />,
          moduleSection: CarModuleSectionCode.PORTFOLIO_ANALYSIS_DASHBOARD,
        },
        {
          path: portfolioAnalysisPages.editPortfolio,
          element: <EditPortfolioPage />,
        },
      ]}
      defaultPath={portfolioAnalysisPages.dashboard}
    />
  );
};
