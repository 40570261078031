import { Box, Typography, styled } from "@mui/material";
import { BoxProps } from "@mui/system";
import { CarTooltipBox } from "./TooltipBox";
import { NavLink } from "react-router-dom";

const APP_WIDTH = 1024;

const PAPER_TOP_SHIFT = 46;

export const paperShiftProps = { marginTop: `-${PAPER_TOP_SHIFT}px` };

export const RootPage = styled(({ children, sx, ...props }: BoxProps) => (
  <Box sx={sx} {...props}>
    {children}
  </Box>
))(({ theme }) => ({
  minHeight: "100vh",
  minWidth: APP_WIDTH,
  width: "100%",
  flex: "auto",
  backgroundColor: theme.palette.gray1,
  display: "flex",
  flexDirection: "column",
  justifyContent: "stretch",
  alignItems: "stretch",
}));

const StyledPaperLink = styled(NavLink)(({ theme }) => ({
  textDecoration: "none",
  minHeight: 34,
  maxHeight: 60,
  border: `solid 1px ${theme.palette.gray3}`,
  borderRadius: "10px 10px 0px 0px",
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4),
  backgroundColor: theme.palette.gray1,
  flexBasis: "content",
  flexShrink: 1,
  display: "flex",
  alignItems: "center",
  position: "relative",
  ".label": {
    fontSize: "15px",
    lineHeight: "18px",
    textAlign: "center",
  },
  "&.active": {
    backgroundColor: theme.palette.white,
    ".label": {
      color: theme.palette.caravelOrangePrimary,
    },
    borderBottomColor: theme.palette.white,
  },
}));

interface RootPagePaperLinkProps {
  className?: string;
  pendoClassName?: string;
  label: string;
  url: string;
  end?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
  target?: React.HTMLAttributeAnchorTarget;
}

export const RootPagePaperLink = (props: RootPagePaperLinkProps) => {
  return (
    <StyledPaperLink
      className={props.className}
      onClick={props.onClick}
      to={props.url}
      end={props.end}
      target={props.target}
    >
      <Typography variant="par01Regular" className="label">
        {props.label}
      </Typography>
      {props.pendoClassName && (
        <CarTooltipBox
          sx={{ position: "absolute", right: 7, top: 8 }}
          className={props.pendoClassName}
        />
      )}
    </StyledPaperLink>
  );
};

export const RootPagePaperLinkBox = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  position: "absolute",
  bottom: "100%",
  zIndex: 1,
  // [theme.breakpoints.down("lg")]: {
  //   paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  // },
}));

export const RootPageTransparentPaper = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(4.5),
  marginRight: theme.spacing(4.5),
  marginBottom: theme.spacing(4.5),
  // [theme.breakpoints.down("lg")]: {
  //   marginLeft: 0,
  //   marginRight: 0,
  //   marginBottom: 0,
  // },
  display: "flex",
  flexDirection: "column",
  flex: "auto",
  position: "relative",
}));

export const RootPageBox = styled(Box)(({ theme }) => ({
  background: theme.palette.white,
  boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05);",
  border: "solid 1px",
  borderRadius: "15px",
  borderColor: theme.palette.gray3,
  display: "flex",
  flexDirection: "column",
  flex: "auto",
}));
